import { useContext } from "react";
import { apiParams, apiUrl } from "../api/api-constants";
import {
  getAxios,
  postAxios,
  putAxios,
  deleteAxios,
} from "../api/axios-config";
import { AppContext } from "../app-context";
import moment from "moment";
import { useGetToken, useLogin } from "./authService";

/**
 * handleAPIResponse function definition section
 * This function helps us to rerun processed response
 * @param respData
 * @returns responseData
 */
export const handleAPIResponse = (respData: any) => {
  return respData;
};

/**
 * useHandleHTTPStatus function definition section
 * This function helps us to return api response if the response statuscode is equal to 200 otherwise redirecting to the login page
 * @returns api response
 */
export const useHandleHTTPStatus = () => {
  const getToken = useGetToken();
  const context = useContext(AppContext);
  const login = useLogin();
  const transformHttpStatusData = async (resp: any) => {
    if (resp.status) {
      switch (resp.status) {
        case 200:
          if (resp.data?.statusCode === 401) {
            getToken()
              .then((tokenResponse: any) => {
                if (tokenResponse.accessToken) {
                  context.setIsLoggedIn(true);
                  context.setUserToken(tokenResponse);
                } else {
                  localStorage.clear();
                  login();
                }
              })
              .catch((error: any) => {
                localStorage.clear();
                login();
              });
          } else {
            return handleAPIResponse(resp.data);
          }
          break;
        case 500:
        case 400:
        case 401:
          getToken()
            .then((tokenResponse: any) => {
              if (tokenResponse.accessToken) {
                context.setIsLoggedIn(true);
                context.setUserToken(tokenResponse);
              } else {
                localStorage.clear();
                login();
              }
            })
            .catch((error: any) => {
              localStorage.clear();
              login();
            });
          break;
        default:
          return resp.data;
      }
    }
  };
  return transformHttpStatusData;
};

/**
 * useActivityLogService function definition section
 * This function helps us to update user active, non active and logout status
 * @returns callActivityLogAPI function
 */
export const useActivityLogService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callActivityLogAPI = async (status: any = "user_active") => {
    try {
      let body: any = {
        status: status,
        time: moment.utc(Date.now()).valueOf(),
      };

      return await postAxios(
        apiUrl.audit,
        { code: apiParams.codeAudit },
        body,
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callActivityLogAPI;
};

/**
 * useConfigService function definition section
 * This function helps us to update user login and reload status
 * @returns callConfigAPI function
 */
export const useConfigService = () => {
  const transformHttpStatusData = useHandleHTTPStatus();
  const callConfigAPI = async (params: any, accessToken: string) => {
    try {
      return await postAxios(
        apiUrl.config,
        { code: apiParams.codeConfig, ...params },
        {},
        accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callConfigAPI;
};

/**
 * useListNodeService function definition section
 * This function helps us to retrieve all nodes data
 * @returns callListNodesAPI function
 */
export const useListNodeService = () => {
  const context = useContext(AppContext);
  const params = {
    owner_uu_ad_id: context.userToken.account.localAccountId,
    owner_email: context.userToken.account.userName,
    owner_name: context.userToken.account.name,
    tree_type: context.treeType,
  };
  const transformHttpStatusData = useHandleHTTPStatus();
  const callListNodesAPI = async () => {
    try {
      return await getAxios(
        apiUrl.node,
        { code: apiParams.codeNode, ...params },
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callListNodesAPI;
};

/**
 * useListActivityLogService function definition section
 * This function helps us to retrieve activity log table data
 * @returns
 */
export const useListActivityLogService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callListActivityLogAPI = async (params: any, type: any) => {
    try {
      return await getAxios(
        apiUrl.audit,
        { code: apiParams.codeAudit, ...params },
        context.userToken.accessToken,
        type
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callListActivityLogAPI;
};

/**
 * useCreateNodeService function definition section
 * This function helps us to create node
 * @returns callCreateNodesAPI function
 */
export const useCreateNodeService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callCreateNodesAPI = async (params: any, payload: any) => {
    try {
      return await postAxios(
        apiUrl.node,
        params,
        payload,
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callCreateNodesAPI;
};

/**
 * useUpdateNodeService function definition section
 * This function helps us to update node data
 * @returns callUpdateNodesAPI function
 */
export const useUpdateNodeService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callUpdateNodesAPI = async (params: any, payload: any) => {
    try {
      return await putAxios(
        apiUrl.node,
        params,
        payload,
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callUpdateNodesAPI;
};

/**
 * useDeleteNodeService function definition section
 * This function helps us to delete node data
 * @returns callDeleteNodesAPI function
 */
export const useDeleteNodeService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callDeleteNodesAPI = async (params: any) => {
    try {
      return await deleteAxios(
        apiUrl.node,
        { code: apiParams.codeNode, ...params },
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callDeleteNodesAPI;
};

/**
 * useGetDepartmentsService function definition section
 * This function helps us to get the departments
 */
export const useGetDepartmentsService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callGetDepartmentListAPI = async (enableStatus: any) => {
    try {
      return await getAxios(
        apiUrl.departments,
        {
          code: apiParams.codeDepartment,
          ...enableStatus,
        },
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };
  return callGetDepartmentListAPI;
};

/**
 * useCreateDepartmentsService function definition section
 * This function helps us to create the department
 */
export const useCreateDepartmentsService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callCreateDepartmentAPI = async (payload: any) => {
    try {
      return await postAxios(
        apiUrl.departments,
        {
          code: apiParams.codeDepartment,
        },
        payload,
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };
  return callCreateDepartmentAPI;
};

/**
 * useUpdateDepartmentsService function definition section
 * This function helps us to update the department
 */
export const useUpdateDepartmentsService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callUpdateDepartmentAPI = async (payload: any) => {
    try {
      return await putAxios(
        apiUrl.departments,
        {
          code: apiParams.codeDepartment,
        },
        payload,
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };
  return callUpdateDepartmentAPI;
};

/**
 * useDeleteDepartmentsService function definition section
 * This function helps us to delete the department
 */
export const useDeleteDepartmentsService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callDeleteDepartmentAPI = async (params: any) => {
    try {
      return await deleteAxios(
        apiUrl.departments,
        { code: apiParams.codeDepartment, ...params },
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error: any) {
      console.log("error", error);
    }
  };
  return callDeleteDepartmentAPI;
};
