/**
 * Here we are exporting constant values globally
 */

export const popupActionNames: any = [
  { Edit: "edit" },
  { Add: "add_circle" },
  { Delete: "delete" },
];

export const month: any = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export const graphColorLegends: any = [
  "#FF5733",
  "#A832E8",
  "#41B845",
  "#F78D12",
  "#6F28C7",
  "#C5E72F",
  "#E63A80",
  "#19D4A1",
  "#FC5B97",
  "#94F021",
  "#D83C3A",
  "#5ED9F8",
  "#D12E64",
  "#70BF49",
  "#F4A937",
  "#3F9C6D",
  "#ED4821",
  "#894EE5",
  "#E2C03D",
  "#763C93",
  "#A6E54B",
  "#F14489",
  "#58E287",
  "#F9A25F",
  "#8F42CA",
  "#E6F82D",
  "#BE2A60",
  "#32C5D8",
  "#E59826",
  "#4360AF",
  "#FFA2D1",
  "#C2F556",
  "#FF4F25",
  "#63BB7F",
  "#C92D98",
  "#0BA457",
  "#E72B1D",
  "#8E4AB7",
  "#E5D53F",
  "#6547BF",
];

export const pageCount: any = [
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];
