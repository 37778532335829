import { useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { timer } from "d3-timer";
/**
 * Interface definition
 */
export type UseAnimatedNodeOptions = {
  animationDuration?: number;
};

const useAnimatedNodes = (
  nodes: any,
  { animationDuration = 300 }: UseAnimatedNodeOptions = {}
) => {
  /**
   * State variable and useReactFlow value extraction section
   */
  const [tmpNodes, setTmpNodes] = useState(nodes);
  const { getNode } = useReactFlow();
  /**
   * UseEffect section
   */
  useEffect(() => {
    const transitions = nodes.map((node: any) => ({
      id: node.id,
      from: getNode(node.id)?.position ?? node.position,
      to: node.position,
      node,
    }));
    /**
     * Setting nodes and edges transitions here
     */
    const t = timer((elapsed) => {
      const s = elapsed / animationDuration;
      const currNodes = transitions.map(({ node, from, to }: any) => {
        return {
          ...node,
          position: {
            x: from.x + (to.x - from.x) * s,
            y: from.y + (to.y - from.y) * s,
          },
        };
      });
      setTmpNodes(currNodes);
      if (elapsed > animationDuration) {
        /**
         * setTmpNodes important to set the final nodes here to avoid glitches
         */
        setTmpNodes(nodes);
        t.stop();
      }
    });
    return () => t.stop();
  }, [nodes, getNode, animationDuration]);

  return { nodes: tmpNodes };
};

export default useAnimatedNodes;
