import { useContext, useEffect } from "react";
import { AppContext } from "../app-context";
import styles from "../styles/alertPopup.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";

const AlertPopUp = () => {
  /**
   * Context and state variable  section
   */
  const context = useContext(AppContext);
  const { message, statusCode, time } = context.alertData;

  /**
   * _handleCancel function definition section
   * This function helps us to close the alert popup
   */
  const _handleCancel = () => {
    context.setIsAlertPopUp(false);
  };

  /**
   * useEffect section
   */
  useEffect(() => {
    setTimeout(() => _handleCancel(), time > 0 ? time : 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={[
          styles.alertContainer,
          statusCode === 200
            ? styles.bg_light_green
            : statusCode === 400
            ? styles.bg_light_red
            : styles.bg_light_blue,
        ].join(" ")}
      >
        <div
          className={[
            styles.symbol,
            fcCSS.flex,
            fcCSS.flexAlignItemsStretch,
          ].join(" ")}
        >
          {statusCode === 200 ? (
            <span className="material-symbols-rounded">done</span>
          ) : statusCode === 400 ? (
            <span className="material-symbols-rounded">error</span>
          ) : (
            <span className="material-symbols-rounded">notifications</span>
          )}
        </div>
        <div className={[styles.alertMsg].join(" ")}>
          <div className={styles.deleteBody}>
            <h4 className={styles.title}>{message}</h4>
          </div>
          <span
            className={["material-symbols-rounded", fcCSS.cursorPtr].join(" ")}
            onClick={_handleCancel}
          >
            close
          </span>
        </div>
      </div>
    </>
  );
};

export default AlertPopUp;
