import { InputField } from "./common/input";
import styles from "../styles/department.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import { AppContext } from "../app-context";
import { useContext, useState } from "react";
import {
  useCreateDepartmentsService,
  useDeleteDepartmentsService,
  useUpdateDepartmentsService,
} from "../utilities/apiService";

const DepartmentDetailForm = () => {
  /**
   * Context and state variable section
   */
  const context = useContext(AppContext);
  const callCreateDepartmentAPI = useCreateDepartmentsService();
  const callUpdateDepartmentAPI = useUpdateDepartmentsService();
  const callDeleteDepartmentAPI = useDeleteDepartmentsService();
  const { departmentAction } = context || {};
  const [departmentData, setDepartmentData] = useState(
    departmentAction.action === "edit_department"
      ? departmentAction.edit
      : departmentAction.add
  );
  const [apiStatus, setApiStatus] = useState(false);

  /**
   * _handleFormSubmit function definition section
   * This function helps us to respective api's based on department actions like create, update and delete
   * @param event
   * @param action
   */
  const _handleFormSubmit = async (event: any, action: any) => {
    context.setAppLoader(true);
    event.preventDefault();
    const data = {
      name: departmentData.name || "",
      color: departmentData.color || "",
    };
    try {
      let payload =
        context.departmentAction.action === "edit_department"
          ? { ...data, _id: context.departmentAction.edit._id }
          : context.departmentAction.action === "add_department"
          ? {
              ...data,
            }
          : {
              id: context.departmentAction.delete._id,
              is_enable:
                context.departmentAction.deleteAction === "Enable" ? 0 : 1,
            };
      const apiRes: any =
        context.departmentAction.action === "edit_department"
          ? await callUpdateDepartmentAPI(payload)
          : context.departmentAction.action === "add_department"
          ? await callCreateDepartmentAPI(payload)
          : await callDeleteDepartmentAPI(payload);
      if (apiRes.status) {
        setApiStatus(apiRes.status);
      } else {
        context.setAppLoader(false);
        context.setNodeActionPopupFlag(false);
      }
      context.setAlertData(apiRes);
      context.setIsAlertPopUp(true);
    } catch (error: any) {
      context.setAlertData({ message: "Error", statusCode: 400 });
      context.setNodeActionPopupFlag(false);
    }
  };

  /**
   * Based on updated node api status, we're doing node action popup hide/show
   */
  if (apiStatus) {
    context.setNodeActionPopupFlag(false);
    context.setIsPageReload(true);
    context.setHighlightNode("");
    context.setAppLoader(false);
    context.setDepartmentAction({
      action: "",
      add: {},
      edit: {},
      delete: {},
      colors: [],
      deleteAction: context.departmentAction.deleteAction,
    });
  }
  /**
   * onchange function definition section
   * This function helps us to capture the input data in action popup
   * @param event
   * @param field
   * @returns input data
   */
  const onChange = (event: any, field?: string) => {
    console.log(event);
    if (field === "department_name") {
      const data = {
        name: event,
      };
      setDepartmentData({ ...departmentData, ...data });
      return;
    } else if (field === "department_color") {
      const data = { color: event };
      setDepartmentData({ ...departmentData, ...data });
      return;
    }
  };
  /**
   * _cancelPopup function definition section
   * This function helps us to close the popup
   */
  const _cancelPopup = () => {
    context.setNodeActionPopupFlag(false);
    context.setDepartmentAction({ ...context.departmentAction, action: "" });
  };
  return (
    <div>
      {context.departmentAction.action !== "delete_department" ? (
        <div>
          <InputField
            id="department-name"
            labelText="Department Name:"
            iType="text"
            value={departmentData.name}
            placeholder={departmentData.name || "Enter Department Name"}
            onChange={(event: any) => onChange(event, "department_name")}
            styles={styles}
          />
          <InputField
            id="department-name"
            labelText="Department Color:"
            iType="color"
            value={departmentData.color || "#ffffff"}
            onChange={(event: any) => onChange(event, "department_color")}
            styles={styles}
          />
        </div>
      ) : (
        <div>
          <div className={[fcCSS.pad10].join(" ")}>
            <h2 className={styles.title}>
              Are you sure you want to
              {departmentAction.deleteAction === "Enable"
                ? " disable "
                : " enable "}
              the &nbsp;
              <b className={styles.fontBold}>
                {context.departmentAction?.delete?.name}
              </b>
              ?
            </h2>
          </div>
        </div>
      )}
      <div className={styles.buttonDiv}>
        <button
          type="button"
          onClick={_cancelPopup}
          className={styles.buttonDiscard}
        >
          Discard
        </button>
        <button
          onClick={(event: any) =>
            _handleFormSubmit(event, context.departmentAction.action)
          }
          className={styles.buttonChange}
        >
          {context.departmentAction.action !== "delete_department"
            ? "Save Changes"
            : "Yes"}
        </button>
      </div>
    </div>
  );
};
export default DepartmentDetailForm;
