/**
 * Here we are exporting dynamic(we can change color, size and style) TickMark, FilledCircle, PlayDownCircle and DropdownIndicator svg's globally
 * @param props
 * @returns svg
 */
export function TickMark(props: any) {
  const { color } = props || {};
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00006 7.80007L1.20006 5.00006L0.266724 5.9334L4.00006 9.66673L12.0001 1.66673L11.0667 0.733398L4.00006 7.80007Z"
        fill={color}
      />
    </svg>
  );
}

export const FilledCircle = (props: any) => {
  const { color, size } = props || { color: "#FFFFFF", size: "small" };
  switch (size) {
    case "large":
      return (
        <svg height="30" width="30">
          <circle
            cx="15"
            cy="15"
            r="12"
            strokeWidth="2"
            fill={`${color}`}
            stroke="#FFFFFF"
          />
        </svg>
      );
    // break;

    default:
      return (
        <svg
          width="22"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="20"
            height="20"
            rx="11"
            fill={`${color}`}
            stroke="#2E2E2E"
          />
        </svg>
      );
    // break;
  }
};

export const PlayDownCircle = (props: any) => {
  const { style } = props || {};
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.4999 2.16666C15.0937 2.16666 18.8333 5.90625 18.8333 10.5C18.8333 15.0937 15.0937 18.8333 10.4999 18.8333C5.90617 18.8333 2.16659 15.0937 2.16659 10.5C2.16659 5.90625 5.90617 2.16666 10.4999 2.16666ZM10.4999 0.0833282C4.74992 0.0833282 0.083252 4.75 0.083252 10.5C0.083252 16.25 4.74992 20.9167 10.4999 20.9167C16.2499 20.9167 20.9166 16.25 20.9166 10.5C20.9166 4.75 16.2499 0.0833282 10.4999 0.0833282ZM10.4999 13.625L6.33325 9.45833H14.6666L10.4999 13.625Z"
        fill="#1C5BFF"
      />
    </svg>
  );
};

export const DropdownIndicator = () => {
  return (
    <div
      style={{
        marginRight: "10px",
        position: "absolute",
        right: "-4px",
        top: "4px",
      }}
    >
      <span className="material-symbols-rounded">expand_more</span>
    </div>
  );
};
