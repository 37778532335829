import { useContext, useEffect, useState } from "react";
import { AppContext } from "../app-context";
import styles from "../styles/legendsList.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import { FilledCircle } from "./common/SvgDynamic";
import { useGetDepartmentsService } from "../utilities/apiService";

const LegendsList = () => {
  /**
   * context section
   */
  const context = useContext(AppContext);
  const [departmentList, setDepartmentList] = useState([]);
  const callGetDepartmentListAPI = useGetDepartmentsService();
  /**
   * cancelFunction function definition section
   * This function help us to set a LegendsListClose context value as true or false.
   */
  const cancelFunction = () => {
    context.setLegendsListClose(context.legendsListClose ? false : true);
  };
  useEffect(() => {
    /**
     * Here we fetch the user information list (graph api) with the help of the userList function.
     * Setting response to setAssignToOptions context variable
     * Setting department names to setDepartmentOptions state variable for department drop down field
     */
    (async () => {
      await callGetDepartmentListAPI({ is_enable: 1 }).then((resp: any) => {
        setDepartmentList(resp.data);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={[styles.legendsList, fcCSS.verticalScrollContainer].join(" ")}
    >
      {context.legendsListClose && (
        <div
          className={[
            styles.legendsListContainer,
            fcCSS.frostedGlassBlackDarkBg,
          ].join(" ")}
        >
          <div
            className={[
              styles.legendContainer,
              fcCSS.flex,
              fcCSS.flexJustifyBetween,
              fcCSS.flexAlignItemsStart,
            ].join(" ")}
          >
            <div className={[styles.legendsListItem].join(" ")}>
              <div className={styles.title}>Type of Data</div>
              <br></br>
              {context.settings?.types_of_nodes?.map(
                (item: any, index: any) => (
                  <div key={index} className={styles.shapeContainer}>
                    <div>
                      <span className="material-symbols-rounded icons">
                        {item?.node_symbol === "oval"
                          ? "circle"
                          : item?.node_symbol === "triangle"
                          ? "change_history"
                          : item?.node_symbol}
                      </span>
                    </div>
                    <div>{item?.node_type}</div>
                  </div>
                )
              )}
            </div>
            <div
              className={[styles.verticalHr, fcCSS.flexAlignSelfStretch].join(
                " "
              )}
            ></div>
            <div className={styles.legendsListItem}>
              <div className={styles.title}>Departments</div>
              <br></br>
              {departmentList?.length > 0 && (
                <>
                  {departmentList?.map((department: any, index: any) => (
                    <div
                      key={"legend-department-" + index}
                      className={styles.shapeContainer}
                    >
                      <div>
                        <FilledCircle color={department.color} size="large" />
                      </div>
                      <div className={styles.padtop5}>{department.name}</div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <span
            onClick={cancelFunction}
            id="legendsClose"
            className={[
              fcCSS.icons,
              fcCSS.d15,
              fcCSS.posAbsolute,
              fcCSS.posTop10px,
              fcCSS.posRight15px,
              fcCSS.cursorPtr,
              styles.fontClr,
              "material-symbols-rounded",
              "icon-cancel",
            ].join(" ")}
          >
            close
          </span>
          {/* <i
            className={[
              fcCSS.icons,
              fcCSS.d15,
              fcCSS.posAbsolute,
              fcCSS.posTop10px,
              fcCSS.posRight10px,

              "icon-cancel",
            ].join(" ")}
          
          ></i> */}
        </div>
      )}
    </div>
  );
};

export default LegendsList;
