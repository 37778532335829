/**
 * symbolRender function definition section
 * This function helps us to get shape names based on node_symbol
 * @param symbol
 * @returns shape name
 */
const symbolRender = (symbol: string) => {
  switch (symbol) {
    case "oval":
      return "circle";
    case "triangle":
      return "change_history";
    case "rectangle":
      return "square";
    default:
      return symbol;
  }
};

export default symbolRender;
