import { useIsAuthenticated } from "@azure/msal-react";
import React, { useContext, useEffect } from "react";
import { getUserAxios } from "../api/axios-config";
import { AppContext } from "../app-context";
import { useGetToken } from "./authService";
import { useNavigate } from "react-router-dom";
import { useConfigService } from "./apiService";

const ProtectedRoute = (props: any) => {
  /**
   * Variables and context section
   */
  let isTriggered = false;
  const isAuthenticated = useIsAuthenticated();
  const context = useContext(AppContext);
  const getToken = useGetToken();
  const navigate = useNavigate();
  const callConfigAPI = useConfigService();
  /**
   * userGroup function definition section
   * This function helps us to find the user's role, whether he/she is an admin or employee.
   * @param uniqueId
   */
  const userGroup = async (uniqueId: any, accessToken: any) => {
    try {
      let apiConfig = {
        url: `https://graph.microsoft.com/v1.0//users/${uniqueId}/memberOf`,
        params: {},
      };
      const userGroupList: any = await getUserAxios(
        apiConfig.url,
        apiConfig.params,
        accessToken
      );

      userGroupList?.data?.value.forEach((x: any) => {
        if (x.displayName.includes("NBL-METRICS-ADMIN")) {
          context.setIsAdmin(true);
        }
      });
    } catch (error) {
      console.log("User Group Error", error);
    }
  };

  /**
   *  function definition section
   * This function helps us to get the access token once the user has successfully logged in. If not, this function will redirect the user to the login page.
   */
  const checkUser = () => {
    isTriggered = true;
    getToken()
      .then((tokenResponse: any) => {
        isTriggered = false;
        if (tokenResponse.accessToken) {
          context.setIsLoggedIn(true);
          context.setUserToken(tokenResponse);
          if (!context.settings?.departments) {
            callConfigAPI(
              {
                status: isAuthenticated ? "logged_in" : "reloaded",
              },
              tokenResponse.accessToken
            ).then((resp) => {
              context.setSettings(resp?.data);
              userGroup(tokenResponse.uniqueId, tokenResponse.accessToken);
            });
          }
        }
      })
      .catch((error: any) => {
        localStorage.clear();
        isTriggered = false;
        navigate("/login");
      });
  };
  /**
   * UseEffect section
   */
  useEffect(() => {
    if (!context.isLoggedIn && !isTriggered) {
      checkUser();
    }
    // eslint-disable-next-line
  }, []);

  return context.isLoggedIn && !isTriggered && props.children ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default ProtectedRoute;
