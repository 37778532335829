import Header from "./Header";
import SideMenu from "./SideMenu";
import "../styles/globals.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import styles from "../styles/department.module.scss";
import { AppContext } from "../app-context";
import { useContext, useEffect, useState } from "react";
import { useGetDepartmentsService } from "../utilities/apiService";
import Popup from "./Popup";
import AlertPopUp from "./AlertPopup";
import Loader from "./Loader";
import PageNotFound from "./404";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { InputField } from "./common/input";
import useDeviceDetect from "./lib/_useDeviceDetector";

const RenderActivityLogs = ({ departmentData }: { departmentData: any }) => {
  /**
   * context definition section
   */
  const context = useContext(AppContext);

  /**
   * _handleDepartmentEdit function definition section
   * This function helps us to set the data for edit and edit action
   * @param item
   */
  const _handleDepartmentEdit = (item: any) => {
    console.log(item);
    context.setNodeActionPopupFlag(true);
    context.setDepartmentAction({
      ...context.departmentAction,
      action: "edit_department",
      edit: item,
    });
  };
  /**
   * _handleDepartmentDelete function definition section
   * This function helps us to set the data for delete and delete action
   * @param item
   */
  const _handleDepartmentDelete = (item: any) => {
    context.setNodeActionPopupFlag(true);
    context.setDepartmentAction({
      ...context.departmentAction,
      action: "delete_department",
      delete: item,
    });
  };

  /**
   * Here we're defining table columns
   */
  const columns = [
    {
      name: "Department Name",
      selector: (row: any) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Department Color",
      selector: (row: any) => (
        <div className={styles.nonColorBoxBorder}>
          <div
            style={{ backgroundColor: `${row.color}` }}
            className={[styles.nonColorBox].join(" ")}
          ></div>
        </div>
      ),
      sortable: false,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row: any) => (
        <div className={[fcCSS.flex].join(" ")}>
          <div className={[styles.editBtn].join(" ")}>
            <button
              onClick={() => _handleDepartmentEdit(row)}
              className={[
                fcCSS.flex,
                fcCSS.flexAlignItemsCenter,
                styles.edit,
                styles.actionButton,
                fcCSS.flexWrap,
                fcCSS.flexJustifyCenter,
              ].join(" ")}
            >
              <span
                className={["material-symbols-rounded", fcCSS.padright3].join(
                  " "
                )}
              >
                edit
              </span>
              Edit
            </button>
          </div>
          <div className={[styles.deleteBtn].join(" ")}>
            <button
              onClick={() => _handleDepartmentDelete(row)}
              className={[
                fcCSS.flex,
                fcCSS.flexAlignItemsCenter,
                styles.delete,
                styles.actionButton,
              ].join(" ")}
            >
              {departmentData[2] === 1 ? (
                <div
                  className={[
                    fcCSS.flex,
                    fcCSS.flexAlignItemsCenter,
                    fcCSS.flexWrap,
                    fcCSS.flexJustifyCenter,
                  ].join(" ")}
                >
                  <span className={["material-symbols-rounded"].join(" ")}>
                    close
                  </span>
                  Disable
                </div>
              ) : (
                <div
                  className={[
                    fcCSS.flex,
                    fcCSS.flexAlignItemsCenter,
                    fcCSS.flexWrap,
                  ].join(" ")}
                >
                  <span className={["material-symbols-rounded"].join(" ")}>
                    done
                  </span>
                  Enable
                </div>
              )}
            </button>
          </div>
        </div>
      ),
      sortable: false,
      wrap: true,
    },
  ];
  let customStyle: any = {
    headRow: {
      style: {
        backgroundColor: "#ECF8FF",
        borderBottom: "none",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: 500,
        fontSize: "14px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={departmentData[0]}
        customStyles={customStyle}
        progressPending={departmentData[1]}
      />
    </>
  );
};

const DepartmentCRUD = () => {
  /**
   * Context, state variable and api function call section
   */
  const context = useContext(AppContext);
  const callGetDepartmentListAPI = useGetDepartmentsService();
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentListCopy, setDepartmentListCopy] = useState([]);
  const [isEnableView, setIsEnableView] = useState(1);
  const { isMobile } = useDeviceDetect();
  const [showFilter, setShowFilter] = useState(true);

  /**
   * _handleDepartmentAdd function definition section
   * This function helps us to set the data for add and add action
   * @param item
   */
  const _handleDepartmentAdd = () => {
    context.setNodeActionPopupFlag(true);
    context.setDepartmentAction({
      ...context.departmentAction,
      action: "add_department",
    });
  };

  /**
   * _handlePageIsUserNotAdmin function definition section
   *  This function helps us to change the page UI based on user role and api status
   * @returns Loader and PageNotFound components
   */
  const _handlePageIsUserNotAdmin = () => {
    if (departmentList.length === 0) {
      return <Loader />;
    } else if (departmentList.length > 0 && !context.isAdmin) {
      return <PageNotFound />;
    }
  };

  /**
   * _handleFilter function definition section
   * This function helps us to filter departments based on department name
   * @param event
   */
  const _handleFilter = (event: any) => {
    setDepartmentList(
      departmentListCopy.filter((item: any) => {
        if (typeof item !== "undefined" && event.length > 0) {
          if (item?.name?.toLowerCase().includes(event.toLowerCase())) {
            return item;
          }
        } else {
          return item;
        }
        // eslint-disable-next-line
        return;
      })
    );
  };

  /**
   * useEffect section
   */
  useEffect(() => {
    context.setAppLoader(true);
    context.setSideMenuBar(false);
    context.setNodeActionPopupFlag(false);
    context.setIsSearchBoxOpen(false);
    context.setExpandAllNodes(false);
    context.setIsPageReload(false);
    context.setLegendsListClose(false);
    if (isMobile) {
      setTimeout(() => {
        setShowFilter(false);
      }, 2000);
    }
    try {
      (async () => {
        let existingColors: any = [];
        await callGetDepartmentListAPI({ is_enable: isEnableView }).then(
          (resp: any) => {
            context.setAppLoader(false);
            setDepartmentList(resp?.data);
            setDepartmentListCopy(resp?.data);
            resp?.data.forEach((item: any) => {
              existingColors.push(item?.color);
            });
          }
        );

        context.setDepartmentAction({
          ...context.departmentAction,
          colors: existingColors,
        });
      })();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [context.isPageReload, isEnableView]);
  return (
    <div>
      {context && context.nodeActionPopupFlag && (
        <div className={styles.legendsPopup}>
          <Popup />
        </div>
      )}
      {context.isAdmin ? (
        <div>
          <div
            className={[
              fcCSS.posFixed,
              context && context.nodeActionPopupFlag
                ? fcCSS.frostedGlassWhiteLiteBackDrop
                : "",
            ].join(" ")}
          ></div>
          <div className={"rootWrapper"}>
            <Header />
            {context.sideMenuBar && <SideMenu />}
            <div className={[styles.parent].join(" ")}>
              <div className={[fcCSS.section1280].join(" ")}>
                <div className={["pageTitleBar"].join(" ")}>
                  <div
                    className={[
                      fcCSS.flex,
                      fcCSS.flexResponsive,
                      fcCSS.flexAlignItemsCenter,
                    ].join(" ")}
                  >
                    <h3>Departments</h3>
                  </div>
                  <div
                    className={[fcCSS.flex, fcCSS.flexAlignItemsCenter].join(
                      " "
                    )}
                  >
                    <div className={[styles.addBtn].join(" ")}>
                      <button
                        onClick={_handleDepartmentAdd}
                        className={[
                          fcCSS.flex,
                          fcCSS.flexAlignItemsCenter,
                          styles.add,
                          styles.actionButton,
                        ].join(" ")}
                      >
                        <span className="material-symbols-rounded">add</span>{" "}
                        Add
                      </button>
                    </div>
                    <div>
                      <Select
                        defaultValue={null}
                        onChange={(event: any) => {
                          setIsEnableView(event.value === "Enable" ? 1 : 0);
                          context.setDepartmentAction({
                            ...context.departmentAction,
                            deleteAction: event.value,
                          });
                        }}
                        options={[
                          { label: "Enabled", value: "Enable" },
                          { label: "Disabled", value: "Disable" },
                        ]}
                        styles={{
                          indicatorSeparator: () => ({
                            display: "none",
                          }),
                          singleValue: (base: any) => ({
                            ...base,
                            paddingRight: "10px",
                            color: "hsl(0, 0%, 20%)",
                            cursor: "pointer",
                          }),
                        }}
                        placeholder={isEnableView ? "Enabled" : "Disabled"}
                        menuPlacement="bottom"
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.dataTable}>
                  <RenderActivityLogs
                    departmentData={[
                      departmentList,
                      context.appLoader,
                      isEnableView,
                    ]}
                  />
                </div>
              </div>
              {context.isAlertPopUp && <AlertPopUp />}
            </div>
            {!context.appLoader && (
              <div
                className={[
                  styles.filterContainer,
                  fcCSS.frostedGlassWhiteLiteBg,
                ].join(" ")}
              >
                {isMobile && (
                  <button
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    className={["btn", fcCSS.posSticky, fcCSS.posRight].join(
                      " "
                    )}
                  >
                    <span className="material-symbols-rounded icons">
                      filter_alt
                    </span>
                  </button>
                )}
                {showFilter && (
                  <div
                    className={[
                      fcCSS.flex,
                      fcCSS.flexResponsive,
                      fcCSS.gap15px,
                      fcCSS.section1280,
                    ].join(" ")}
                  >
                    <div className={[styles.filterInput].join(" ")}>
                      <div>
                        <h5>Search :</h5>
                        <InputField
                          id="department-name"
                          iType="text"
                          placeholder={"Department Name"}
                          onChange={(event: any) => _handleFilter(event)}
                          styles={styles}
                        />
                      </div>
                    </div>

                    <div
                      className={[
                        styles.filterInput,
                        fcCSS.flex,
                        fcCSS.flexAlignItemsCenter,
                        fcCSS.mrgntop15,
                      ].join(" ")}
                    >
                      <div className={fcCSS.pad5}></div>
                      {/* <button
                      onClick={_handleFilterReset}
                      className="bgLessRedBtn"
                    >
                      Reset
                    </button> */}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>{_handlePageIsUserNotAdmin()}</div>
      )}
    </div>
  );
};
export default DepartmentCRUD;
