import { useState, useContext } from "react";
import DeleteNode from "./DeleteNode";
import { AppContext } from "../app-context";
import { cancelFunction } from "../utilities/helper";
import NodeDetailsForm from "./AddEditNodeForm";
import styles from "../styles/popup.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import stylesLegend from "../styles/dashboard.module.scss";
import "../styles/graph.module.scss";
import { popupActionNames } from "./common/Constants";
import DepartmentDetailForm from "./AddEditDepartmentForm";

const Popup = () => {
  /**
   * State variables and context section
   */
  const context = useContext(AppContext);
  const [popup, setPopup] = useState(true);
  const [addNode, setAddNode] = useState(false);
  const [editNode, setEditNode] = useState(false);
  const [deleteNode, setDeleteNode] = useState(false);
  /**
   * addNodeHandler, editNodeHandler, deleteNodeHandler,cancelPopup functions definition section
   * All these functions help us to set a node action popup value as true or false.
   */
  const addNodeHandler = () => {
    setAddNode(!addNode);
    popup && setPopup(false);
  };
  const editNodeHandler = () => {
    setEditNode(!editNode);
    popup && setPopup(false);
  };
  const deleteNodeHandler = () => {
    setDeleteNode(!deleteNode);
    popup && setPopup(false);
  };
  const cancelPopup = () => {
    context.setDepartmentAction({ ...context.departmentAction, action: "" });
    cancelFunction("popup", setEditNode, context, "-150px");
  };

  return (
    <>
      {addNode && (
        <div className={stylesLegend.legendsPopup}>
          <NodeDetailsForm
            popup={popup}
            isEdit={false}
            setPopup={setPopup}
            setAddNode={setAddNode}
            setEditNode={setEditNode}
          />
        </div>
      )}
      {editNode && (
        <div className={stylesLegend.legendsPopup}>
          <NodeDetailsForm
            popup={popup}
            isEdit={true}
            setPopup={setPopup}
            setAddNode={setAddNode}
            setEditNode={setEditNode}
          />
        </div>
      )}
      {deleteNode && (
        <div className={stylesLegend.legendsPopup}>
          <DeleteNode
            popup={popup}
            setPopup={setPopup}
            setDeleteNode={setDeleteNode}
          />
        </div>
      )}
      {popup && (
        <div
          id="popupAnimation"
          className={
            `${popup}` ? [styles.show].join(" ") : styles.popupContainer
          }
        >
          <div className={styles.popUpHeader}>
            {context.departmentAction.action === "add_department" ? (
              <h1>Add</h1>
            ) : context.departmentAction.action === "edit_department" ? (
              <h1>Edit</h1>
            ) : context.departmentAction.action === "delete_department" ? (
              <h1>
                {context.departmentAction.deleteAction === "Enable"
                  ? "Disable"
                  : "Enable"}
              </h1>
            ) : (
              <h1>Action</h1>
            )}

            <span
              className={[
                "material-symbols-rounded",
                "icons",
                fcCSS.cursorPtr,
              ].join(" ")}
              onClick={cancelPopup}
            >
              close
            </span>
          </div>
          <div className={styles.popUpBody}>
            {context.departmentAction.action === "" ? (
              popupActionNames.map((item: any, index: any) => (
                <button
                  key={index}
                  className={styles.button}
                  onClick={
                    Object.keys(item)[0] === "Edit"
                      ? editNodeHandler
                      : Object.keys(item)[0] === "Add"
                      ? addNodeHandler
                      : deleteNodeHandler
                  }
                >
                  <div
                    className={[
                      styles.buttonContainer,
                      `${Object.keys(item)[0].toLocaleLowerCase()}Color`,
                    ].join(" ")}
                  >
                    <span className="material-symbols-rounded icons">
                      {item[Object.keys(item)[0]]}
                    </span>
                    <p className={styles.editNode}>{Object.keys(item)[0]}</p>
                  </div>
                  <span className="material-symbols-rounded icons">
                    chevron_right
                  </span>
                </button>
              ))
            ) : (
              <div>
                <DepartmentDetailForm />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
