import axios from "axios";
/**
 * apiResponse function definition section
 * This function helps us to check and return the api response after getting response from CRUD apo's
 * @param apiConfig
 * @returns response
 */
export const apiResponse = async (apiConfig: any) => {
  try {
    const response = await axios(apiConfig);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
/**
 * postAxios function definition section
 * This function helps us to call post/create api
 * @param url
 * @param params
 * @param body
 * @param accessToken
 * @returns response
 */
export const postAxios = async (
  url: any,
  params: any,
  body: any,
  accessToken: any
) => {
  const api = {
    method: "POST",
    url: url,
    headers: {
      Authorization: accessToken,
    },
    params: params,
    data: { ...body },
  };
  return await apiResponse(api);
};
/**
 * getAxios function definition section
 * This function helps us to call get/list api
 * @param url
 * @param params
 * @param accessToken
 * @param responseType
 * @returns return response to "apiResponse" function
 */
export const getAxios = async (
  url: any,
  params?: any,
  accessToken?: any,
  responseType: string = "json"
) => {
  let api = {
    method: "GET",
    url: url,
    headers: {
      Authorization: accessToken,
    },
    params: params,
    data: {},
    responseType: responseType,
  };
  return await apiResponse(api);
};
/**
 * putAxios function definition section
 * This function helps us to call put/update api
 * @param url
 * @param params
 * @param body
 * @param accessToken

 * @returns return response to "apiResponse" function
 */
export const putAxios = async (
  url: any,
  params: any,
  body: any,
  accessToken: any
) => {
  let apiConfig = {
    method: "PUT",
    url: url,
    headers: {
      Authorization: accessToken,
    },
    params: params,
    data: { ...body },
  };
  return await apiResponse(apiConfig);
};
/**
 * deleteAxios function definition section
 * This function helps us to call delete/disable api
 * @param url
 * @param params
 * @param accessToken
 * @returns return response to "apiResponse" function
 */
export const deleteAxios = async (
  url: any,
  params: { code: string | undefined; id: any },
  accessToken: any
) => {
  let api = {
    method: "DELETE",
    url,
    params,
    headers: {
      Authorization: accessToken,
    },
  };
  return await apiResponse(api);
};
/**
 * getUserAxios function definition section
 * This function helps us to call users information graph get/list api (graph api- provided by microsoft. this api have AD users information)
 * @param url
 * @param params
 * @param accessToken
 * @returns return response to "apiResponse" function
 */
export const getUserAxios = async (
  url: any,
  params?: any,
  accessToken?: any
) => {
  let api = {
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: params,
    data: {},
  };
  return await apiResponse(api);
};
