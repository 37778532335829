import { useCallback, useState, useEffect, useContext, useMemo } from "react";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  Background,
  OnNodesChange,
  OnEdgesChange,
  NodeMouseHandler,
  Edge,
  MarkerType,
  useReactFlow,
} from "reactflow";
import CustomNode from "./CustomNode";
import useAnimatedNodes from "./lib/_useAnimateNodes";
import useExpandCollapse from "./lib/_useExpandCollapse";
import Header from "./Header";
import Popup from "./Popup";
import { AppContext } from "../app-context";
import LegendsList from "./LegendsList";
import "reactflow/dist/style.css";
import styles from "../styles/dashboard.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import Search from "./Search";
import SideMenu from "./SideMenu";

import useDeviceDetect from "./lib/_useDeviceDetector";
import { useListNodeService } from "../utilities/apiService";
import { NavLink } from "react-router-dom";
import AlertPopUp from "./AlertPopup";
/**
 * Assigned nodeArray variable and its value type is array
 */
let nodeArray: any = [];
/**
 * Setting initial and dummy node value to nodeArray
 */
nodeArray.push({
  id: "1",
  position: { x: 0, y: 0 },
  data: {
    expanded: true,
    nodeInfo: {
      dependant_departments: [],
      parent_id: "",
      dashboard_link: "",
    },
  },
});
/**
 * Setting proOptions for react flow
 */
const proOptions = { account: "paid-pro", hideAttribution: true };
/**
 * Defining types for treeWidth, treeHeight and animationDuration parameters like a Interface
 */
type ExpandCollapseExampleProps = {
  treeWidth?: number;
  treeHeight?: number;
  animationDuration?: number;
};

function ReactFlowPro({
  treeWidth = 420,
  treeHeight = 400,
  animationDuration = 300,
}: ExpandCollapseExampleProps = {}) {
  /**
   * Extracting react flow values and methods
   * State variables and context section
   */
  const { fitView } = useReactFlow();
  const context = useContext(AppContext);
  const [nodes, setNodes] = useState<any>(nodeArray);
  const [edges, setEdges] = useState<Edge<any>[]>([]);
  const [apiResponse, setApiResponse] = useState<any>(null);
  const { isMobile } = useDeviceDetect();
  const callListNodesAPI = useListNodeService();
  const [isTreeSwitch, setIsTreeSwitch] = useState(false);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  /**
   * Memorizing OrganizationTree component and assigning to nodeType constant
   */
  const nodeTypes = useMemo(() => {
    return { custom: CustomNode };
  }, []);
  /**
   * The useExpandCollapse method will return all nodes and edges from api response and we assign those values to corresponding variables
   */
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
    nodes,
    edges,
    { treeWidth, treeHeight }
  );
  /**
   * The useAnimatedNodes method helps us to add animation for nodes (expand and collapse)
   */
  const { nodes: animatedNodes } = useAnimatedNodes(visibleNodes, {
    animationDuration,
  });
  /**
   * onNodesChange, onEdgesChange, onNodeClick section
   * If any changes (click-expand and click-collapse) happen in the node tree structure, this useCallback helps us to set visible nodes and edges to the corresponding variables (nodes, edges).
   * Visible nodes and edges - we can see those nodes and edges in expanded view
   */
  const onNodesChange: OnNodesChange = useCallback(
    (changes: any) => setNodes((nds: any) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: any) => setEdges((eds: any) => applyEdgeChanges(changes, eds)),
    []
  );
  const onNodeClick: NodeMouseHandler = useCallback(
    (_: any, node: { id: any }) => {
      let zoomNodes: any = [{ id: node.id }];
      setNodes((nds: any) =>
        nds.map((n: any) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }
          if (n?.data?.nodeInfo?.parent_id === node?.id) {
            zoomNodes.push({ id: n?.data?.nodeInfo?._id });
          }
          return n;
        })
      );

      setTimeout(() => {
        fitView({
          duration: 400,
          nodes: zoomNodes,
          includeHiddenNodes: true,
        });
      }, 100);
    },
    [setNodes, fitView]
  );
  /**
   * _legendsPopup function definition section
   * This function helps us to show and hide the legends popup
   */
  const _legendsPopup = () => {
    context.setLegendsListClose(!context?.legendsListClose);
  };
  /**
   * _showAllNodes function definition section
   * This function helps us to expand and collapse all the nodes
   */
  const _showAllNodes = () => {
    context.setExpandAllNodes(!context.expandAllNodes);
  };
  /**
   * _handleTreeSwitch function definition section
   * This function helps us to set a current tree type and reset all other features like legends, expand-collapse and filter
   */
  const _handleTreeSwitch = (treeTypeValue: any) => {
    context.setExpandAllNodes(false);
    context.setIsSearchBoxOpen(false);
    context.setSearchListingNodes([]);
    context.setLegendsListClose(false);
    setIsTreeSwitch(true);
    context.setTreeType(treeTypeValue);
  };

  /**
   * _handleFullScreenView function definition section
   * This function helps us to enable and disable the full screen mode
   */
  const _handleFullScreenView = async () => {
    if (!isFullScreenMode) {
      document.documentElement.requestFullscreen();
      setIsFullScreenMode(true);
    } else {
      document.exitFullscreen();
      setIsFullScreenMode(false);
    }
  };
  /**
   * useEffect section
   */
  useEffect(() => {
    context.setNodeActionPopupFlag(false);
    context.setSideMenuBar(false);
    const initialElements: any = async () => {
      context.setAppLoader(true);
      let nodeArray: any = [];
      let edgeArray: any = [];
      let apiRes: any;
      /**
       * Here we are using the callListNodesAPI method to fetch the information from nodes api and reducing unnecessary api calls
       * After that, we segregate, align and set the nodes and edges based on parent and child relation.
       */
      if (context.isPageReload) {
        apiRes = await callListNodesAPI();
      } else {
        apiRes =
          apiResponse && !isTreeSwitch
            ? apiResponse
            : (context.setNodeLevel(0), await callListNodesAPI());
      }
      if (apiRes?.status) {
        context.setAppLoader(false);
        setIsTreeSwitch(false);
      }
      setApiResponse(apiRes);
      context.setNodeApiResponse(apiRes.data);
      const { data } = apiRes || {};
      data?.all_nodes?.forEach((item: any) => {
        if (item?.parent_id === "") {
          nodeArray.push({
            id: item["_id"],
            position: { x: 0, y: 0 },
            data: {
              expanded: true,
              nodeInfo: item || {},
            },
          });
        } else {
          nodeArray.push({
            id: item["_id"],
            position: { x: 0, y: 0 },
            data: {
              expanded:
                context.expandAllNodes || item.level <= context.nodeLevel - 1
                  ? true
                  : false,
              nodeInfo: item || {},
            },
          });
        }
        const { parent_id, _id } = item || {};
        if (parent_id !== "") {
          edgeArray.push({
            id: `${parent_id}->${_id}`,
            source: parent_id,
            target: _id,
            type: "smoothstep",
            markerEnd: {
              type: MarkerType.Arrow,
              color: "#1C5BFF",
              width: 15,
              height: 13,
            },
            style: {
              strokeWidth: 2,
              stroke: "#1C5BFF",
            },
            pathOptions: { offset: 20, borderRadius: 40 },
          });
        }
      });
      /**
       * Setting segregated nodes and edges
       */
      setNodes(nodeArray);
      setEdges(edgeArray);
      context.setIsPageReload(false);
      setTimeout(() => {
        fitView({ duration: 400 });
      }, 100);
    };
    if (context.userToken.account) {
      if (!context.AppLoader) {
        initialElements();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fitView,
    context.userToken.account,
    context.expandAllNodes,
    context.treeType,
    context.isPageReload,
    context.nodeLevel,
  ]);

  useEffect(() => {
    /**
     * Setting auto focus node information to zoomNodes array variable while doing search
     */
    setTimeout(() => {
      let zoomNodes: any = [];
      zoomNodes.push({ id: context.highlightNode });
      fitView({
        duration: 400,
        nodes: zoomNodes,
        includeHiddenNodes: true,
      });
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.highlightNode]);

  return (
    <>
      <Header />
      {context.sideMenuBar && <SideMenu />}
      {nodes.length > 1 && <Search nodes={nodes} />}
      <Background />
      {context && context.nodeActionPopupFlag && (
        <div className={fcCSS.frostedGlassWhiteLiteBackDrop}></div>
      )}
      {context && context.nodeActionPopupFlag && (
        <div className={styles.legendsPopup}>
          <Popup />
        </div>
      )}
      {context.legendsListClose && <LegendsList />}
      {!isMobile && (
        <div
          className={[
            "dock",
            "left",
            "desktopVisible",
            fcCSS.frostedGlassWhiteLiteBg,
          ].join(" ")}
        >
          <div className={["dockSection"].join(" ")}>
            <div className="count">
              {Math.floor(apiResponse?.data?.kpi_count ?? 0)
                .toString()
                .padStart(2, "0")}
            </div>
            <div className="label">KPI</div>
          </div>
          <div className={["dockSection"].join(" ")}>
            <div className="count">
              {Math.floor(apiResponse?.data?.metric_count ?? 0)
                .toString()
                .padStart(2, "0")}
            </div>
            <div className="label">Metric</div>
          </div>
          {/* <div className={["dockSection"].join(" ")}>
          <div className="count">
            {Math.floor(apiResponse?.data?.category_count ?? 0)
              .toString()
              .padStart(2, "0")}
          </div>
          <div className="label">Category</div>
        </div> */}
          <div className={["dockSection"].join(" ")}>
            <div className="count">
              {Math.floor(apiResponse?.data?.datapoint_count ?? 0)
                .toString()
                .padStart(2, "0")}
            </div>
            <div className="label">Data Point</div>
          </div>
        </div>
      )}
      <div
        className={[
          "dock",
          isMobile ? "bottom" : "right",
          fcCSS.frostedGlassWhiteLiteBg,
        ].join(" ")}
      >
        <div
          className={["dockSection", fcCSS.cursorPtr].join(" ")}
          onClick={() => {
            context.setIsSearchBoxOpen(!context.isSearchBoxOpen);
          }}
        >
          <div className="count">
            <span className="material-symbols-rounded icons">search</span>
          </div>
          <div className="label">Search</div>
        </div>
        {context.expandAllNodes && (
          <div
            className={["dockSection", fcCSS.cursorPtr].join(" ")}
            onClick={_showAllNodes}
          >
            <div className="count">
              <span className="material-symbols-rounded icons">
                collapse_all
              </span>
            </div>
            <div className="label">Collapse</div>
          </div>
        )}{" "}
        {!context.expandAllNodes && (
          <div
            className={["dockSection", fcCSS.cursorPtr].join(" ")}
            onClick={_showAllNodes}
          >
            <div className="count">
              <span className="material-symbols-rounded icons">expand_all</span>
            </div>
            <div className="label">Expand</div>
          </div>
        )}
        <div
          className={["dockSection", fcCSS.cursorPtr].join(" ")}
          onClick={() => {
            _handleFullScreenView();
          }}
        >
          <div className="count">
            <span className="material-symbols-rounded icons">pan_zoom</span>
          </div>
          <div className="label">Focus Mode</div>
        </div>
        <div
          className={["dockSection", fcCSS.cursorPtr].join(" ")}
          onClick={_legendsPopup}
        >
          <div className="count">
            <span className="material-symbols-rounded icons">info</span>
          </div>
          <div className="label">Legend</div>
        </div>
        {!isMobile && (
          <NavLink to="/compare">
            <div className={["dockSection", fcCSS.cursorPtr].join(" ")}>
              <div className="count">
                <span className="material-symbols-rounded icons">compare</span>
              </div>
              <div className="label">Compare</div>
            </div>
          </NavLink>
        )}
      </div>
      <div
        className={[
          "dock",
          "top",
          fcCSS.horizontalScrollContainer,
          fcCSS.frostedGlassBlueLiteBg,
        ].join(" ")}
      >
        <div
          className={["dockSection", fcCSS.cursorPtr].join(" ")}
          onClick={() => {
            _handleTreeSwitch("organization");
          }}
        >
          <div
            className={[
              "label",
              context.treeType === "organization" && "active",
            ].join(" ")}
          >
            <span className="material-symbols-rounded icons">account_tree</span>
            KPI Tree
          </div>
        </div>
        <div
          className={["dockSection", fcCSS.cursorPtr].join(" ")}
          onClick={() => {
            _handleTreeSwitch("people");
          }}
        >
          <div
            className={[
              "label",
              context.treeType === "people" && "active",
            ].join(" ")}
          >
            <span className="material-symbols-rounded icons">group</span>People
            Metrics
          </div>
        </div>
        {/* <div
          className={["dockSection", fcCSS.cursorPtr].join(" ")}
          onClick={() => {
            _handleTreeSwitch("improvement");
          }}
        >
          <div
            className={[
              "label",
              context.treeType === "improvement" && "active",
            ].join(" ")}
          >
            <span className="material-symbols-rounded icons">
              self_improvement
            </span>
            CI Metrics
          </div>
        </div> */}
      </div>
      {context.isAlertPopUp && <AlertPopUp />}
      <ReactFlow
        id="treeContainer"
        fitView
        nodes={animatedNodes}
        edges={visibleEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={onNodeClick}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        className={styles.viewport}
        zoomOnDoubleClick={false}
        elementsSelectable={false}
        minZoom={-Infinity}
        maxZoom={1}
      ></ReactFlow>
    </>
  );
}

const Dashboard = (props: any) => {
  return (
    <ReactFlowProvider>
      <ReactFlowPro {...props} />
    </ReactFlowProvider>
  );
};

export default Dashboard;
