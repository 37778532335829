import Select from "react-select";
import makeAnimated from "react-select/animated";
const selectComponentAnimation = makeAnimated();
export function SelectDropdown(props: any) {
  /**
   * Props Destructure section
   */
  const {
    id,
    labelText,
    defaultValues,
    options,
    onChange,
    placeholder,
    styles,
    dropDownArrow,
  } = props;
  /**
   * It returns dropdown UI with respective options (users name/departments/node types)
   */
  return (
    <>
      <label className={styles.label} htmlFor={id}>
        {labelText}
      </label>
      <div className={styles.formGroup}>
        <Select
          defaultValue={defaultValues}
          onChange={onChange}
          options={options}
          className={styles.dropdown}
          components={dropDownArrow}
          styles={{
            indicatorSeparator: () => ({ display: "none" }),
            control: () => ({
              height: "30px",
            }),
          }}
          placeholder={placeholder}
        />
      </div>
    </>
  );
}

export function MultiSelectDropdown(props: any) {
  /**
   * Props Destructure section
   */
  const {
    id,
    labelText,
    defaultValues,
    options,
    onChange,
    placeholder,
    styles,
  } = props;
  /**
   * It returns dropdown UI with respective options (users name/departments/node types)
   */
  return (
    <>
      <label className={styles.label} htmlFor={id}>
        {labelText}
      </label>
      <div className={styles.formGroup}>
        <Select
          components={selectComponentAnimation}
          isMulti
          onChange={(event: any) => {
            onChange(event, "dependant_departments");
          }}
          value={defaultValues}
          options={options}
          className="dropdown"
          styles={{
            indicatorSeparator: () => ({ display: "none" }),
            singleValue: (base) => ({
              ...base,
            }),
          }}
          placeholder={placeholder}
        />
      </div>
    </>
  );
}

export function MultiSelectDropUp(props: any) {
  /**
   * Props Destructure section
   */
  const { onChange, value, options, styles } = props;
  /**
   * It returns dropUp UI with respective options (users name/departments/ status)
   */
  return (
    <>
      <Select
        isMulti
        defaultValue={null}
        onChange={(event: any) => onChange(event, "user_name")}
        value={value}
        options={options}
        className={["dropdown", styles.customDropDown].join(" ")}
        styles={{
          indicatorSeparator: () => ({ display: "none" }),
          singleValue: (base) => ({
            ...base,
            paddingRight: "10px",
            color: "hsl(0, 0%, 20%)",
            cursor: "pointer",
          }),
        }}
        placeholder={"Choose"}
        menuPlacement="top"
      />
    </>
  );
}
