import { useMsal } from "@azure/msal-react";
import { msalConfigParams } from "../api/api-constants";

/**
 * useLogin function definition section
 * This function helps us to redirect the user to MSAL login page
 * @returns login
 */
export const useLogin = () => {
  const { instance } = useMsal();
  const login = () => {
    instance.loginRedirect({
      scopes: msalConfigParams.scopes,
    });
  };
  return login;
};

/**
 * useLogout function definition section
 * This function helps us to redirect the user to MSAL log out page
 * @returns logout
 */
export const useLogout = () => {
  const { instance } = useMsal();
  const logout = () => {
    instance.logout();
  };
  return logout;
};

/**
 * useIsAuthenticated function definition section
 * This function helps us to check whether the user is authorized or not
 * @returns useIsAuthenticated
 */
export const useIsAuthenticated = () => {
  const { accounts } = useMsal();

  const isAuthenticated = () => {
    return !!accounts[0];
  };
  return isAuthenticated;
};

/**
 * useGetToken function definition section
 * This function helps us to get token for logged-in user
 * @returns getToken
 */
export const useGetToken = () => {
  const { instance, accounts } = useMsal();

  const getToken = async () => {
    const response = await instance.acquireTokenSilent({
      scopes: msalConfigParams.scopes,
      account: accounts[0],
    });

    return response;
  };

  return getToken;
};
