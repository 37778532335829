import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import fcCSS from "../styles/flexcssable.module.scss";
import styles from "../styles/logIn.module.scss";
import { useLogin } from "../utilities/authService";
import "../styles/globals.scss";

const Login = () => {
  /**
   * Extracting values from msal, navigation assignment and function call section
   */
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const login = useLogin();

  /**
   * UseEffect section
   */
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard", { state: { path: location.pathname } });
    } else {
      localStorage.clear();
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <div
      className={[
        fcCSS.flex,
        fcCSS.flexAlignItemsCenter,
        fcCSS.flexJustifyCenter,
        fcCSS.height100vh,
        fcCSS.flexDirectionCol,
      ].join(" ")}
    >
      <div
        className={[
          fcCSS.flex,
          fcCSS.flexAlignItemsCenter,
          styles.textContainer,
        ].join(" ")}
      >
        <div
          id={styles.logo}
          className={[fcCSS.imgResponsive, fcCSS.d100].join(" ")}
        ></div>
        <p
          className={[
            styles.verticalHr,
            fcCSS.mrgnleft10,
            fcCSS.mrgnright10,
          ].join(" ")}
        ></p>
        <p>Metrics</p>
      </div>
      <div>
        <button className={["mediumBtn"].join(" ")} onClick={login}>
          LOGIN
        </button>
      </div>
      <div
        className={[fcCSS.posAbsolute, fcCSS.posBtm5px, styles.footer].join(
          " "
        )}
      >
        <p>
          ©&nbsp;<span>{new Date().getFullYear()}</span> Niagara Bottling, LLC.
          All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Login;
