import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import {
  BrowserCacheLocation,
  Configuration,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfigParams } from "./api/api-constants";
import "./index.scss";
/**
 * MSAL configuration section
 * This configuration object allows you to configure important elements of MSAL functionality and is passed into the constructor of PublicClientApplication
 */
const configuration: Configuration = {
  auth: {
    clientId: msalConfigParams.clientId as string,
    authority: msalConfigParams.authority,
    redirectUri: msalConfigParams.redirectUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
    iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
    loadFrameTimeout: 9000, // Applies to both silent and popup calls - In milliseconds
    loggerOptions: {
      piiLoggingEnabled: true,
    },
  },
};

/**
 * PublicClientApplication initialization section
 * PublicClientApplication used to instantiate the PublicClientApplication object
 */
const pca = new PublicClientApplication(configuration);
pca.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

/**
 * React dom section
 * We are setting up the application root and rendering our application here
 */
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

/**
 * If you want to start measuring performance in your app, pass a function to log results (for example: reportWebVitals(console.log)) or send to an analytics endpoint.
 */
reportWebVitals();
