import { useContext, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { AppContext } from "../app-context";
import { FilledCircle, PlayDownCircle } from "./common/SvgDynamic";
import styles from "../styles/customNode.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import NodeStatus from "./NodeStatus";
import { apiParams } from "../api/api-constants";
import symbolRender from "./common/RenderSymbol";
import { useUpdateNodeService } from "../utilities/apiService";

/**
 * _getNodeData function definition section
 * This function returns the label for the node based on the current state
 * @param param0
 * @returns node information
 */
const _getNodeData = ({ nodeInfo }: any) => {
  return nodeInfo || {};
};

const CustomNode = ({ data }: NodeProps) => {
  /**
   * State variables and context section
   */
  const context = useContext(AppContext);
  const [apiStatus, setApiStatus] = useState(false);
  const [dropdownComponent, setDropdownComponent] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    data["nodeInfo"]["status"] || "Pending"
  );
  const callUpdateNodesAPI = useUpdateNodeService();

  /**
   * nodeData have the node information based on the state of the node
   */
  const nodeData = _getNodeData(data);
  /**
   * Here we check whether the dashboard_lik has the prefix of "https"
   * Based on setting a boolean value for dashboard_link_file
   */
  if (
    nodeData["dashboard_link"] &&
    (nodeData["dashboard_link"].startsWith("https://") ||
      nodeData["dashboard_link"].startsWith("http://"))
  ) {
    nodeData["dashboard_link_file"] = false;
  } else {
    nodeData["dashboard_link_file"] = true;
  }
  /**
   * _setNodeActionPopupFlag function definition section
   * This function helps us to show and hide the node action popup (Add, Edit and Delete)
   */
  const _setNodeActionPopupFlag = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    context.setNodeActionPopupFlag(true);
    context.setNodeData(data["nodeInfo"]);
  };
  /**
   * existingItemCheck function definition section
   * This function helps us to check whether the selected node is already exist in compare list or not
   * @returns isExisting and item
   */
  const existingItemCheck = () => {
    let item = data["nodeInfo"];
    let isExisting: any = context.compareNodeData.some(
      (existingItem: any) => existingItem._id === item._id
    );
    return { isExisting, item };
  };
  /**
   * _isCheckBoxChecked function definition section
   * This function helps us to check and un check the check box based on node availability in compare page
   * @returns boolean
   */
  const _isCheckBoxChecked = () => {
    let { isExisting }: any = existingItemCheck();
    if (isExisting) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * This function helps us to call _handleNodeCompareAction function
   * @param evt
   */
  const _callNodeCompareAction = (evt: any) => {
    evt.stopPropagation();
    _handleNodeCompareAction();
  };
  /**
   * _handleNodeCompareAction function definition section
   * This function helps us to add and remove nodes from compare node list
   * @param evt
   */
  const _handleNodeCompareAction = () => {
    let { isExisting, item }: any = existingItemCheck();
    if (isExisting) {
      context.setCompareNodeData(
        context.compareNodeData.filter(
          (item: any) => item._id !== data["nodeInfo"]._id
        )
      );
    } else if (!isExisting && context.compareNodeData.length > 4) {
      // alert("Can't compare more then 5");
      context.setAlertData({
        message: "Can't compare more then 5",
        statusCode: 201,
      });
      context.setIsAlertPopUp(true);
    } else {
      context.setCompareNodeData((prev: any) => [...prev, item]);
    }
    context.setNodeActionPopupFlag(false);
  };
  /**
   * handleOptionClick function definition section
   * This function helps us to change the node status
   */
  const handleOptionClick = async (option: any) => {
    setSelectedOption(option);
    const body = {
      _id: data.nodeInfo._id,
      status: option || data.nodeInfo.status,
    };
    let params = {
      code: apiParams.codeNode,
    };
    try {
      const apiRes: any = await callUpdateNodesAPI(params, body);
      let { status } = apiRes;
      setApiStatus(status);
      context.setAlertData(apiRes);
      context.setIsAlertPopUp(true);
    } catch (error: any) {
      context.setAlertData({ message: "Error", statusCode: 400 });
      context.setIsAlertPopUp(true);
      context.setNodeActionPopupFlag(false);
    }
  };
  if (apiStatus) {
    context.setNodeActionPopupFlag(false);
    context.setIsPageReload(true);
  }
  /**
   * preventClick function definition
   * This function prevents the action popup menu while clicking.
   * While click node can't expand and collapse
   */
  const preventClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDropdownComponent(dropdownComponent ? false : true);
  };

  return (
    <article className={styles.node}>
      {/* header */}
      {nodeData.parent_id !== "" ? (
        <div className={[styles.nodeHeader].join(" ")}>
          <div className={[styles.nodeName].join(" ")}>
            <div
              className={[
                styles.deptHover,
                styles.tooltip,
                styles.tooltiptextNodeType,
              ].join(" ")}
            >
              <span
                className={[styles.tooltiptextNodeType].join(" ")}
              >{`${nodeData["node_type"]}`}</span>

              <span className="material-symbols-rounded icons">
                {symbolRender(nodeData["node_symbol"])}
              </span>
            </div>

            <div className={styles.nodeCenter}>
              <h3 className={styles.nodeTitle1}>{nodeData["node_name"]}</h3>
            </div>
          </div>
          <a
            target={"_blank"}
            href={nodeData["sharepoint_link"]}
            rel="noreferrer"
          >
            <div className={styles.infoIcon}>
              {nodeData["sharepoint_link"] && (
                <span className="material-symbols-rounded icons">info</span>
              )}
            </div>
          </a>
        </div>
      ) : (
        <div className={styles.nbNodeStyle}>
          <h1 className={[styles.triggerNodeContent].join(" ")}>
            {nodeData["node_name"]}
          </h1>
        </div>
      )}
      {/* header*/}
      {/* body */}
      <div className={[styles.nodeBody].join(" ")}>
        {nodeData.parent_id !== "" && (
          <div>
            {nodeData["dashboard_link"] !== "" &&
              nodeData["dashboard_link"] !== "TBD" &&
              nodeData["dashboard_link"] !== null && (
                <>
                  <>
                    {nodeData["dashboard_link_file"] === false && (
                      <a
                        style={{ textDecoration: "none" }}
                        target={"_blank"}
                        href={nodeData["dashboard_link"]}
                        rel="noreferrer"
                      >
                        <div
                          className={[
                            styles.deptHover,
                            styles.dashTooltip,
                            styles.dashTooltiptext,
                          ].join(" ")}
                        >
                          <button className={[styles.cardButton].join(" ")}>
                            <span
                              className={[styles.dashTooltiptext].join(" ")}
                            >{`${nodeData["dashboard_link"].slice(
                              0,
                              50
                            )}...`}</span>
                            <span className="material-symbols-rounded icons">
                              link
                            </span>
                            Dashboard
                          </button>
                        </div>
                      </a>
                    )}
                  </>
                  <>
                    {nodeData["dashboard_link_file"] === true && (
                      <div
                        className={[
                          styles.deptHover,
                          styles.dashTooltip,
                          styles.dashTooltiptext,
                          "cursorPtr",
                        ].join(" ")}
                        onClick={() => {
                          // copy to clipboard
                          navigator.clipboard
                            .writeText(nodeData["dashboard_link"])
                            .then(
                              () => {
                                context.setAlertData({
                                  message:
                                    "This dashboard is located on Niagara's Shared Drive. The path is now copied to your clipboard. Once you are connected to the Niagara network, you may paste this path into your file explorer to access it.",
                                  statusCode: 200,
                                  time: 8000,
                                });
                                context.setIsAlertPopUp(true);
                                // alert(
                                //   "This dashboard is located on Niagara's Shared Drive. The path is now copied to your clipboard. Once you are connected to the Niagara network, you may paste this path into your file explorer to access it."
                                // );
                              },
                              () => {
                                /* clipboard write failed */
                              }
                            );
                        }}
                      >
                        <button className={[styles.cardButton].join(" ")}>
                          <span className="material-symbols-rounded icons">
                            content_copy
                          </span>
                          Copy to clipboard
                        </button>
                      </div>
                    )}
                  </>
                </>
              )}
          </div>
        )}
        {nodeData.parent_id !== "" && (
          <p className={styles.allDepartment}>
            {(nodeData["assigned_to"] !== "" ||
              nodeData["assigned_to"] !== null ||
              nodeData["assigned_to"] !== undefined) &&
              nodeData["assigned_to"].length !== 0 &&
              nodeData["assigned_to"].includes("@") > 0 && (
                <>
                  <b>Custodian:</b>
                  <br />
                  <a
                    className={fcCSS.wrdBreakAll}
                    href={`mailto:${nodeData["assigned_to"]}`}
                  >
                    {nodeData["assigned_to"]}
                  </a>
                </>
              )}
            {(nodeData["assigned_to"] !== "" ||
              nodeData["assigned_to"] !== null ||
              nodeData["assigned_to"] !== undefined) &&
              nodeData["assigned_to"].length !== 0 &&
              nodeData["assigned_to"].includes("@") <= 0 && (
                <>
                  <b>Custodian:</b>
                  <br />
                  {nodeData["assigned_to"]}
                </>
              )}
          </p>
        )}
        {/* circles */}
        <div className={styles.deptClass}>
          <div>
            {nodeData["dependant_departments"].map((item: any, index: any) => (
              <div
                key={nodeData["_id"] + `_department_` + index}
                className={[
                  styles.deptHover,
                  styles.tooltip,
                  styles.tooltiptext,
                ].join(" ")}
              >
                <span
                  className={[styles.tooltiptext].join(" ")}
                >{`${item["name"]}`}</span>
                <FilledCircle color={`${item.color}`} size="small" />
              </div>
            ))}
          </div>
          <div className="flex actionsContainer">
            {/* Parent Path */}
            {nodeData.parent_path &&
              nodeData.parent_path.length > 1 &&
              nodeData.parent_id !== "" && (
                <>
                  <div
                    className={[
                      styles.deptHover,
                      styles.tooltip,
                      styles.tooltiptextNodeType,
                    ].join(" ")}
                  >
                    <span className={[styles.tooltiptextNodeType].join(" ")}>
                      {nodeData.parent_path.map((a: any, index: any) => (
                        <span key={`parent-` + index}>
                          {a.node_name}
                          {nodeData.parent_path.length > index + 1 && ` > `}
                        </span>
                      ))}
                    </span>
                    <button className={[styles.actionIcons].join(" ")}>
                      <span className="material-symbols-rounded icons">
                        account_tree
                      </span>
                    </button>
                  </div>
                </>
              )}
            {/* menu */}
            {context.isAdmin && nodeData.parent_id !== "" ? (
              <button
                className={[styles.actionIcons].join(" ")}
                onClick={_setNodeActionPopupFlag}
              >
                <span className="material-symbols-rounded icons">
                  more_vert
                </span>
              </button>
            ) : null}
          </div>
        </div>
        {nodeData.parent_id !== "" && (
          <div
            className={[
              fcCSS.flex,
              fcCSS.flexAlignItemsCenter,
              fcCSS.flexJustifyBetween,
              fcCSS.width100,
            ].join(" ")}
          >
            <div>
              {context.treeType === "organization" && (
                <div
                  onClick={_callNodeCompareAction}
                  className={[styles.addToCompare, fcCSS.cursorPtr].join(" ")}
                >
                  <span>
                    <input
                      className={fcCSS.cursorPtr}
                      type="checkbox"
                      onChange={_handleNodeCompareAction}
                      checked={_isCheckBoxChecked()}
                    />
                  </span>
                  Add to compare
                </div>
              )}
            </div>

            <div
              className={[
                styles.deptHover,
                styles.tooltip,
                styles.tooltiptext,
                styles.levelIndicator,
              ].join(" ")}
            >
              <span className={[styles.tooltiptextNodeType].join(" ")}>
                level {nodeData.level}
              </span>
              {nodeData.level}
            </div>
          </div>
        )}

        {context.isAdmin &&
          nodeData.parent_id !== "" &&
          context.treeType === "organization" && (
            <NodeStatus
              preventClick={preventClick}
              selectedOption={selectedOption}
              dropdownComponent={dropdownComponent}
              handleOptionClick={handleOptionClick}
            />
          )}
        <Handle position={Position.Top} type="target" />
        <Handle position={Position.Bottom} type="source" />
        {data.expandable && (
          <>
            <div
              className={[
                styles.dropDown,
                data.expanded ? styles.flipVertical : "",
              ].join(" ")}
            >
              <PlayDownCircle
                style={{ backgroundColor: "white", borderRadius: "50%" }}
              />
            </div>
          </>
        )}
      </div>
    </article>
  );
};
export default CustomNode;
