/**
 * Here we are defining and exporting api url's, codes and MSAL config parameters to respective variables
 * We get the values from .env files
 */
const apiUrl: any = {
  users: process.env.REACT_APP_USERS_API,
  node: process.env.REACT_APP_NODE_API,
  departments: process.env.REACT_APP_DEPARTMENT_API,
  config: process.env.REACT_APP_CONFIG_API,
  audit: process.env.REACT_APP_AUDIT_API,
};
const apiParams: any = {
  codeNode: process.env.REACT_APP_NODE_API_CODE,
  codeDepartment: process.env.REACT_APP_DEPARTMENT_API_CODE,
  codeConfig: process.env.REACT_APP_CONFIG_API_CODE,
  codeAudit: process.env.REACT_APP_AUDIT_CODE,
};
const msalConfigParams: any = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: process.env.REACT_APP_AUTHORITY,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scopes: ["User.Read.All", "Profile", "GroupMember.Read.All"], // Replace with the desired scope(s) for your API
};

export { apiUrl, msalConfigParams, apiParams };
