import { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import styles from "../styles/activityLog.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import "../styles/globals.scss";
import { apiParams } from "../api/api-constants";
import { AppContext } from "../app-context";
import DatePicker from "react-multi-date-picker";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import Header from "./Header";
import SideMenu from "./SideMenu";
import ActivityLogExpandCollapse from "./ActivityLogExpandCollapse";
import { MultiSelectDropUp } from "./common/select-dropdown/index";
import Select from "react-select";
import PageNotFound from "./404";
import Loader from "./Loader";
import { NavLink } from "react-router-dom";
import useDeviceDetect from "./lib/_useDeviceDetector";
import { useListActivityLogService } from "../utilities/apiService";
import { pageCount } from "./common/Constants";

/**
 * RenderActivityLogs function definition section
 * This function helps us to define table columns and populate table information
 * @param auditData
 * @returns Data table
 */
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const RenderActivityLogs = ({ auditData }: { auditData: any }) => {
  /**
   * Here we're defining columns
   */
  const columns = [
    {
      name: "Sr No",
      selector: (row: any, index: any) =>
        auditData[0].page_no * auditData[0].page_size +
        (index + 1) -
        auditData[0].page_size,
      sortable: false,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email ID",
      selector: (row: any) => row.user_email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Department",
      selector: (row: any) => (row.department === null ? "NA" : row.department),
      sortable: true,
      wrap: true,
    },
    {
      name: "Job title",
      selector: (row: any) => (row.job_title === null ? "NA" : row.job_title),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      cell: (row: any) => (
        <span className="pill">
          {["user_active", "logged_in", "reloaded"].includes(row.status)
            ? "User Active"
            : row.status.replaceAll("_", " ")}
        </span>
      ),
      sortable: true,
      wrap: true,
      width: "170px",
    },
    {
      name: "Log Date/Time",
      selector: (row: any) =>
        moment(row.created_on).format("MMMM Do, YYYY, hh:mm:ss.SSS a"),
      format: (row: any) => {
        return moment
          .tz(row.created_on, timeZone)
          .format("MMMM Do, YYYY, hh:mm:ss.SSS a");
      },
      sortable: true,
      wrap: true,
    },
  ];
  let customStyle: any = {
    headRow: {
      style: {
        backgroundColor: "#ECF8FF",
        borderBottom: "none",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: 500,
        fontSize: "14px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={auditData[0].audit_logs}
        expandableRows={true}
        expandableRowsComponent={ActivityLogExpandCollapse}
        expandableRowExpanded={() => !true}
        customStyles={customStyle}
        progressPending={auditData[1]}
      />
    </>
  );
};

const ActivityLog = () => {
  /**
   * state variables, context  and function call section
   */
  const context = useContext(AppContext);
  const { isMobile } = useDeviceDetect();
  const [showFilter, setShowFilter] = useState(true);
  const [auditApiAuditLogResponse, setAuditApiAuditLogResponse] = useState<any>(
    []
  );
  const [userNameOption, setUserNameOption] = useState([]);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [statusOption, setStatusOption] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [totalPages, setTotalPages] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [paramsObject, setParamsObject] = useState<any>({
    code: apiParams.codeAudit,
    page_no: "1",
    page_size: "12",
  });
  const [selectedFilterData, setSelectedFilterData] = useState<any>({
    userName: [],
    department: [],
    status: [],
  });
  const callListActivityLogAPI = useListActivityLogService();

  /**
   * stringConcatenation function definition section
   * This function helps us to concatenate the filter inputs
   * @param event
   * @param key
   * @returns concatenated string (selectedOptionString)
   */
  const stringConcatenation = (event: any, key: any, field: any) => {
    let selectedOptionString: any = "";
    event.forEach((item: any) => {
      selectedOptionString =
        selectedOptionString !== ""
          ? selectedOptionString + "," + item[key]
          : selectedOptionString + item[key];
    });
    setParamsObject({ ...paramsObject, [field]: selectedOptionString });
  };

  /**
   * onChange function definition section
   * This function helps us to set search/filter data to corresponding variables based on various search/filter fields
   * @param event
   * @param field
   */
  const onChange = (event: any, field: any) => {
    if (field === "users") {
      setSelectedFilterData({ ...selectedFilterData, userName: event });
      stringConcatenation(event, "value", field);
    } else if (field === "department") {
      setSelectedFilterData({ ...selectedFilterData, department: event });
      stringConcatenation(event, "label", field);
    } else if (field === "status") {
      setSelectedFilterData({ ...selectedFilterData, status: event });
      stringConcatenation(event, "label", field);
    }
  };

  /**
   * _handleValueLabelSet function definition section
   * This function helps us to set a search/filter field of predefined options
   * @param apiResponse
   */
  const _handleValueLabelSet = (apiResponse: any) => {
    let { users, departments, log_status } = apiResponse.data;
    let userArray: any = [];
    let departmentArray: any = [];
    let statusArray: any = [];
    // audit_logs.map((item: any, index: any) => (item["slr_no"] = index + 1));
    setAuditApiAuditLogResponse(apiResponse.data);
    users.forEach((item: any) =>
      userArray.push({ value: item.id, label: item.display_name })
    );
    setUserNameOption(userArray);
    departments.map((item: any, index: any) =>
      departmentArray.push({ value: index, label: item.department })
    );
    setDepartmentOption(departmentArray);
    log_status.map((item: any, index: any) =>
      statusArray.push({ value: index, label: item.status })
    );
    setStatusOption(statusArray);
  };

  /***
   * getAuditApiResponse function definition section
   * Here we're calling get audit api and setting response values to corresponding state variables
   */
  const getAuditApiResponse = async () => {
    await callListActivityLogAPI(
      { ...paramsObject },

      ""
    ).then((apiResponse: any) => {
      context.setAppLoader(false);
      if (apiResponse?.status) {
        if (apiResponse?.data?.audit_logs?.length > 0) {
          setNoResultsFound(false);
        } else {
          setNoResultsFound(true);
        }
        _handleValueLabelSet(apiResponse);
        setTotalPages(apiResponse?.data?.total_pages);
      } else {
        setNoResultsFound(true);
      }
    });
  };

  /**
   * _handleExcelExportAction function definition section
   * This function helps us to save/download the audit log data as .zip format
   */
  const _handleExcelExportAction = async () => {
    await callListActivityLogAPI(paramsObject, "blob").then((res: any) => {
      saveAs(res.data, `${moment(Date.now()).format()}.zip`);
      paramsObject["excel"] = false;
    });
  };

  /**
   * _handleFilter function definition section
   * This function helps us to trigger the getAuditApiResponse function when the filter action is required
   */
  const _handleFilter = async () => {
    paramsObject["page_no"] = "1";
    paramsObject["page_size"] = "12";
    context.setAppLoader(true);
    getAuditApiResponse();
  };

  /**
   * _handleExcelExportBtn function definition section
   * This function helps us to export the audit log data as a csv file format
   */
  const _handleExcelExportBtn = () => {
    paramsObject["excel"] = true;
    _handleExcelExportAction();
  };

  /**
   * _handleGoToFirstPage function definition section
   * This function helps us to navigate first page
   */
  const _handleGoToFirstPage = () => {
    setParamsObject({ ...paramsObject, page_no: "1" });
  };

  /**
   * _handleGoToLastPage function definition section
   * This function helps us to navigate last page
   */
  const _handleGoToLastPage = () => {
    setParamsObject({ ...paramsObject, page_no: totalPages });
  };

  /**
   * _handlePageIncrement function definition section
   * This function helps to increase the page number, one page at a time
   */
  const _handlePageIncrement = () => {
    let pageIncrement: any = (
      Number(paramsObject["page_no"]) + 1 <= Number(totalPages)
        ? Number(paramsObject["page_no"]) + 1
        : 1
    ).toString();
    setParamsObject({ ...paramsObject, page_no: pageIncrement });
  };

  /**
   * _handlePageDecrement function definition section
   * This function helps to decrease the page number, one page at a time
   */
  const _handlePageDecrement = () => {
    let pageDecrement = (
      Number(paramsObject["page_no"]) - 1 > 0
        ? Number(paramsObject["page_no"]) - 1
        : 1
    ).toString();
    setParamsObject({ ...paramsObject, page_no: pageDecrement });
  };

  /**
   * _handleFilterReset function definition section
   * This function helps us to reset all the filters
   */
  const _handleFilterReset = () => {
    setSelectedFilterData({ userName: [], department: [], status: [] });
    setParamsObject({
      code: apiParams.codeAudit,
      page_no: "1",
      page_size: "12",
    });
    setIsReset(true);
  };

  /**
   * _handlePageNumberOption function definition section
   * This function helps us to form page number options
   * @returns page number option array
   */
  const _handlePageNumberOption = () => {
    let pageNumberOption: any = [];
    for (let i: any = 1; i <= Number(totalPages); i++) {
      pageNumberOption.push({ value: i.toString(), label: i.toString() });
    }
    return pageNumberOption;
  };

  /**
   * _handlePageIsUserNotAdmin function definition section
   *  This function helps us to change the page UI based on user role and api status
   * @returns Loader and PageNotFound components
   */
  const _handlePageIsUserNotAdmin = () => {
    if (auditApiAuditLogResponse?.total_count === 0) {
      return <Loader />;
    } else if (auditApiAuditLogResponse?.total_count > 0 && !context.isAdmin) {
      return <PageNotFound />;
    }
  };

  /**
   * useEffect section
   * Here we're doing an audit api call
   */
  useEffect(() => {
    context.setAppLoader(true);
    context.setSideMenuBar(false);
    context.setIsSearchBoxOpen(false);
    context.setExpandAllNodes(false);
    getAuditApiResponse();
    setIsReset(false);
    if (isMobile) {
      setTimeout(() => {
        setShowFilter(false);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    paramsObject["page_no"],
    // eslint-disable-next-line
    paramsObject["page_size"],
    isReset,
  ]);

  return (
    <>
      {context.isAdmin ? (
        <div className={"rootWrapper"}>
          <Header />
          {context.sideMenuBar && <SideMenu />}
          <div className={[styles.parent].join(" ")}>
            <div className={[fcCSS.section1280].join(" ")}>
              <div className={["pageTitleBar"].join(" ")}>
                <div
                  className={[
                    fcCSS.flex,
                    fcCSS.flexResponsive,
                    fcCSS.flexAlignItemsCenter,
                  ].join(" ")}
                >
                  <h3>Activity Log</h3>
                  <NavLink to="/activities/graph">
                    <button
                      className={[
                        "bgLessBlueBtn",
                        fcCSS.flex,
                        fcCSS.flexAlignItemsCenter,
                      ].join(" ")}
                    >
                      <>
                        Graph View
                        <span
                          className={[
                            "material-symbols-rounded icons",
                            fcCSS.mrgnleft5,
                          ].join(" ")}
                        >
                          trending_up
                        </span>
                      </>
                    </button>
                  </NavLink>
                </div>
                <div>
                  <button
                    onClick={_handleExcelExportBtn}
                    className={["btn"].join(" ")}
                  >
                    <span
                      className={["material-symbols-rounded icons"].join(" ")}
                    >
                      download
                    </span>
                    Export Excel
                  </button>
                </div>
              </div>
              {!noResultsFound ? (
                <div className={styles.dataTable}>
                  <RenderActivityLogs
                    auditData={[auditApiAuditLogResponse, context.appLoader]}
                  />
                </div>
              ) : (
                <div className="emptyState">
                  <div>
                    <div className="image"></div>
                    <div className="title">No Activities Recorded</div>
                    <div className="caption">
                      Please come back after sometime
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className={[styles.pageNavContainer].join(" ")}>
                  {!context.appLoader && !noResultsFound && (
                    <div className={styles.paginatorContainer}>
                      <div
                        className={[
                          fcCSS.flex,
                          fcCSS.flexAlignItemsCenter,
                        ].join(" ")}
                      >
                        Rows per page &nbsp;
                        <span className={[].join(" ")}>
                          <Select
                            defaultValue={null}
                            onChange={(event: any) =>
                              setParamsObject({
                                ...paramsObject,
                                page_size: event.value,
                                page_no: "1",
                              })
                            }
                            options={pageCount}
                            styles={{
                              indicatorSeparator: () => ({
                                display: "none",
                              }),
                              singleValue: (base: any) => ({
                                ...base,
                                paddingRight: "10px",
                                color: "hsl(0, 0%, 20%)",
                                cursor: "pointer",
                              }),
                            }}
                            placeholder={paramsObject["page_size"]}
                            menuPlacement="top"
                          />
                        </span>
                        &nbsp; &nbsp;
                      </div>
                      <div
                        className={[
                          fcCSS.flex,
                          fcCSS.flexAlignItemsCenter,
                        ].join(" ")}
                      >
                        Page &nbsp;
                        <span>
                          <Select
                            defaultValue={null}
                            onChange={(event: any) =>
                              setParamsObject({
                                ...paramsObject,
                                page_no: event.value,
                              })
                            }
                            options={_handlePageNumberOption()}
                            styles={{
                              indicatorSeparator: () => ({
                                display: "none",
                              }),
                              singleValue: (base: any) => ({
                                ...base,
                                paddingRight: "10px",
                                color: "hsl(0, 0%, 20%)",
                                cursor: "pointer",
                              }),
                            }}
                            placeholder={paramsObject["page_no"]}
                            menuPlacement="top"
                          />
                        </span>
                        &nbsp; of &nbsp;{totalPages} &nbsp; &nbsp; &nbsp; &nbsp;
                      </div>

                      <div className={[styles.gap15px, fcCSS.flex].join(" ")}>
                        <p onClick={_handleGoToFirstPage}>
                          <span className="material-symbols-rounded">
                            first_page
                          </span>
                        </p>
                        <p onClick={_handlePageDecrement}>
                          <span className="material-symbols-rounded icons">
                            keyboard_arrow_left
                          </span>
                        </p>
                        <p onClick={_handlePageIncrement}>
                          <span className="material-symbols-rounded icons">
                            chevron_right
                          </span>
                        </p>
                        <p onClick={_handleGoToLastPage}>
                          <span className="material-symbols-rounded">
                            last_page
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!context.appLoader && (
              <div
                className={[
                  styles.filterContainer,
                  fcCSS.frostedGlassWhiteLiteBg,
                ].join(" ")}
              >
                {isMobile && (
                  <button
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    className={["btn", fcCSS.posSticky, fcCSS.posRight].join(
                      " "
                    )}
                  >
                    <span className="material-symbols-rounded icons">
                      filter_alt
                    </span>
                  </button>
                )}
                {showFilter && (
                  <div
                    className={[
                      fcCSS.flex,
                      fcCSS.flexResponsive,
                      fcCSS.gap15px,
                      fcCSS.section1280,
                    ].join(" ")}
                  >
                    <div className={[styles.filterInput].join(" ")}>
                      <div>
                        <h5>Date Range :</h5>
                      </div>
                      <DatePicker
                        range
                        editable={false}
                        placeholder="Choose"
                        format="MM-DD-YYYY"
                        onChange={(dates: any) => {
                          dates !== null &&
                            dates[0] !== "" &&
                            setParamsObject({
                              ...paramsObject,
                              start_date: dates[0],
                              end_date: dates[1],
                            });
                        }}
                        value={[
                          paramsObject["start_date"],
                          paramsObject["end_date"],
                        ]}
                        className={[styles.dateRangeInput].join(" ")}
                      />
                    </div>
                    <div className={[styles.filterInput].join(" ")}>
                      <div>
                        <h5>Name :</h5>
                      </div>
                      <MultiSelectDropUp
                        onChange={(event: any) => onChange(event, "users")}
                        value={selectedFilterData.userName}
                        options={userNameOption}
                        styles={styles}
                      />
                    </div>
                    <div className={[styles.filterInput].join(" ")}>
                      <div>
                        <h5>Department :</h5>
                      </div>
                      <MultiSelectDropUp
                        onChange={(event: any) => onChange(event, "department")}
                        value={selectedFilterData.department}
                        options={departmentOption}
                        styles={styles}
                      />
                    </div>
                    <div className={[styles.filterInput].join(" ")}>
                      <div>
                        <h5>Status :</h5>
                      </div>
                      <MultiSelectDropUp
                        onChange={(event: any) => onChange(event, "status")}
                        value={selectedFilterData.status}
                        options={statusOption}
                        styles={styles}
                      />
                    </div>
                    <div
                      className={[
                        styles.filterInput,
                        fcCSS.flex,
                        fcCSS.flexAlignItemsCenter,
                        fcCSS.mrgntop15,
                      ].join(" ")}
                    >
                      <button
                        onClick={_handleFilter}
                        className={["btn"].join(" ")}
                      >
                        <span className="material-symbols-rounded icons">
                          filter_list
                        </span>
                        Filter
                      </button>
                      <div className={fcCSS.pad5}></div>
                      <button
                        onClick={_handleFilterReset}
                        className="bgLessRedBtn"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>{_handlePageIsUserNotAdmin()}</div>
      )}
    </>
  );
};

export default ActivityLog;
