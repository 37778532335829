export function TextAreaField(props: any) {
  /**
   * Props Destructure section
   */
  const { id, labelText, value, placeholder, onChange, styles } = props || {};
  /**
   * It returns Input field UI with respective information (place holder and values)
   */
  return (
    <>
      <label className={styles.label} htmlFor={id}>
        {labelText}
      </label>
      <div className={styles.formGroup}>
        <textarea
          className={styles.textarea}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </>
  );
}
