import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { AppContext } from "../app-context";
import fcCSS from "../styles/flexcssable.module.scss";
import styles from "../styles/search.module.scss";
import { getDepartmentsOpts } from "../utilities/helper";
import useDeviceDetect from "./lib/_useDeviceDetector";
import symbolRender from "./common/RenderSymbol";

const Search = (nodes: any) => {
  /**
   * Variables and context section
   */
  const context = useContext(AppContext);
  const [selectedDepartmentsCopy, setSelectedDepartmentsCopy] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [resetBtn, setResetBtn] = useState(false);
  const selectComponentAnimation = makeAnimated();
  const { isMobile } = useDeviceDetect();
  let searchTextEle: any;
  let searchLevelEle: any;
  let selectedDepartments: any = selectedDepartmentsCopy
    ? selectedDepartmentsCopy
    : [];
  let nodeNameOptionsArray: any = [];
  /**
   * Filter nodes function definition section
   * This function helps us to filter nodes based on node name (search text) and node departments.
   * This function is also capable of doing combination search (based on both node name and node department)
   */
  const filterNodes = () => {
    setResetBtn(false);
    if (
      (selectedDepartments.length !== 0 || searchTextEle.value.length !== 0) &&
      searchLevelEle.value.length === 0
    ) {
      context.setExpandAllNodes(true);
    }
    context.setSearchListingNodes(
      // eslint-disable-next-line
      nodes.nodes.filter((item: any) => {
        if (
          typeof item !== "undefined" &&
          item.data.nodeInfo.parent_path.length > 0
        ) {
          if (
            selectedDepartments.length === 0 ||
            (selectedDepartments.length > 0 &&
              selectedDepartments.some((substring: any) => {
                item.data.nodeInfo.department_ids =
                  item.data.nodeInfo.dependant_departments.map(
                    (a: any) => a._id
                  );
                return item.data.nodeInfo.department_ids.includes(
                  substring.value
                );
              }))
          ) {
            setSelectedDepartmentsCopy(selectedDepartments);
            if (
              searchTextEle.value.length === 0 ||
              item.data.nodeInfo.node_name
                .toLowerCase()
                .includes(searchTextEle.value.toLowerCase())
            ) {
              if (
                searchLevelEle.value.length === 0 ||
                item.data.nodeInfo.level === Number(searchLevelEle.value)
              ) {
                return item;
              }
            }
          }
        }
      })
    );
  };

  /**
   * zoomToNode function definition section
   * This function helps us to set a node that is zoomed after a search.
   * @param node_id
   */
  const zoomToNode = (node_id: any) => {
    if (isMobile) {
      context.setIsSearchBoxOpen(!context.isSearchBoxOpen);
    }
    context.setHighlightNode(node_id);
  };
  /**
   * onSearchInputChange, onDepartmentChange and onSearchLevelChange function definition section
   * These functions help us to collect search text, departments and node level
   * @param event
   */
  const onSearchInputChange = (event: any) => {
    if (searchTextEle.value === "" && searchLevelEle.value === "") {
      _handleReset();
    } else {
      filterNodes();
    }
  };
  const onDepartmentChange = (event: any) => {
    selectedDepartments = event;
    filterNodes();
  };
  const onSearchLevelChange = () => {
    if (searchLevelEle.value === "" && searchTextEle.value === "") {
      // context.setNodeLevel(0);
      _handleReset();
    } else {
      filterNodes();
    }
  };
  /**
   * cancelFunction function definition section
   * This function basically doing  toggle action based on UI cancel action
   * Also, this function helps to set a context variable of the expandAllNodes value as false
   */
  const cancelFunction = () => {
    context.setIsSearchBoxOpen(!context.isSearchBoxOpen);
    _handleReset();
  };
  /**
   * Search input field reset function definition section
   */
  const _handleReset = () => {
    context.setExpandAllNodes(false);
    context.setSearchListingNodes([]);
    context.setNodeLevel(0);
    context.setHighlightNode("");
    setSelectedDepartmentsCopy([]);
    if (
      typeof searchTextEle !== "undefined" ||
      typeof searchLevelEle !== "undefined"
    ) {
      searchTextEle.value = "";
      searchLevelEle.value = "";
    }

    setResetBtn(true);
  };
  /**
   * existingItemCheck function definition section
   * This function helps us to check whether the selected node is already exist in compare list or not
   * @returns isExisting and item
   */
  const existingItemCheck = (nodeItem: any) => {
    let item = nodeItem;
    let isExisting: any = context.compareNodeData.some(
      (existingItem: any) => existingItem._id === item._id
    );
    return { isExisting, item };
  };
  /**
   * _isCheckBoxChecked function definition section
   * This function helps us to check and un check the check box based on node availability in compare page
   * @returns boolean
   */
  const _isCheckBoxChecked = (nodeItem: any) => {
    let { isExisting }: any = existingItemCheck(nodeItem);
    if (isExisting) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * _handleNodeCompareAction function definition section
   * This function helps us to add and remove nodes from compare node list
   * @param evt
   */
  const _handleNodeCompareAction = (nodeItem: any) => {
    let { isExisting, item }: any = existingItemCheck(nodeItem);
    if (isExisting) {
      context.setCompareNodeData(
        context.compareNodeData.filter((item: any) => item._id !== nodeItem._id)
      );
    } else if (!isExisting && context.compareNodeData.length > 4) {
      context.setAlertData({
        message: "Can't compare more then 5",
        statusCode: 201,
      });
      context.setIsAlertPopUp(true);
      // alert("Can't compare more then 5");
    } else {
      context.setCompareNodeData((prev: any) => [...prev, item]);
    }
    context.setNodeActionPopupFlag(false);
  };

  /**
   * This function helps us to call _handleNodeCompareAction function
   * @param evt
   */
  const _callNodeCompareAction = (nodeItem: any) => {
    _handleNodeCompareAction(nodeItem);
  };

  /**
   * renderList function definition section
   * This function helps us to create a node list UI based on search results
   * @returns node list
   */
  const renderList = () => {
    return context.searchListingNodes?.map((item: any, index: any) => (
      <article key={`card-` + index} className={styles.nodeSearchCard}>
        <div
          className={styles.sectionGroup}
          onClick={(event) => {
            zoomToNode(item.data.nodeInfo._id);
          }}
        >
          <div className={styles.title}>
            <span
              className={[
                fcCSS.icons,
                fcCSS.d25,
                fcCSS.mrgnright5,
                fcCSS.flexNoShrink,
                "material-symbols-rounded icons",
              ].join(" ")}
            >
              {symbolRender(item.data.nodeInfo.node_symbol)}
            </span>
            <div>{item.data.nodeInfo.node_name}</div>
          </div>
          <span
            className={[
              fcCSS.icons,
              fcCSS.d25,
              fcCSS.flexNoShrink,
              styles.clr1C5BFF,
              "material-symbols-rounded",
            ].join(" ")}
          >
            my_location
          </span>
        </div>
        <div className={styles.nodeSearchCardBody}>
          <label>Path:</label>
          {item.data.nodeInfo.parent_path.map((a: any, index: any) => (
            <span key={`parent-` + index}>
              <span
                className={styles.nodeItem}
                onClick={(event) => {
                  zoomToNode(a._id);
                }}
              >
                {a.node_name}
              </span>
              {item.data.nodeInfo.parent_path.length > index + 1 && (
                <span
                  className={[
                    styles.caret,
                    styles.iconPath,
                    "material-symbols-rounded",
                  ].join(" ")}
                >
                  arrow_forward_ios
                </span>
              )}
            </span>
          ))}
          <br />
          {item.data.nodeInfo.child_path.length > 0 && (
            <>
              <label>
                Next Layers ({item.data.nodeInfo.child_path.length}):
              </label>

              <div>
                {item.data.nodeInfo.child_path.map((a: any, index: any) => (
                  <span key={`child-` + index}>
                    <span
                      className={styles.nodeItem}
                      onClick={(event) => {
                        zoomToNode(a._id);
                      }}
                    >
                      {a.node_name}
                    </span>
                    {item.data.nodeInfo.child_path.length > index + 1 && (
                      <span className={styles.caret}>&nbsp;|&nbsp;</span>
                    )}
                  </span>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.horizontal}></div>
        {item.data.nodeInfo.parent_id !== "" && (
          <div>
            <div
              className={[
                fcCSS.flex,
                fcCSS.flexAlignItemsCenter,
                fcCSS.flexJustifyBetween,
                fcCSS.width100,
                fcCSS.pad10,
              ].join(" ")}
            >
              {context.treeType === "organization" && (
                <div
                  onClick={() => _callNodeCompareAction(item.data.nodeInfo)}
                  className={[styles.addToCompare, fcCSS.cursorPtr].join(" ")}
                >
                  <span>
                    <input
                      className={fcCSS.cursorPtr}
                      type="checkbox"
                      onChange={() =>
                        _handleNodeCompareAction(item.data.nodeInfo)
                      }
                      checked={_isCheckBoxChecked(item.data.nodeInfo)}
                    />
                  </span>
                  Add to compare
                </div>
              )}
              <div className={[styles.levelIndicator].join(" ")}>
                {item.data.nodeInfo.level}
              </div>
            </div>
          </div>
        )}
      </article>
    ));
  };
  /**
   * This if condition helps us to assign a node name and node id to nodeNameOptionsArray on non empty node scenario
   */
  if (nodes?.nodes?.length > 1) {
    nodes?.nodes?.forEach((e: any) => {
      nodeNameOptionsArray.push({
        value: e.data.nodeInfo._id,
        label: e.data.nodeInfo.node_name,
      });
    });
  }
  /**
   * useEffect definition.
   */
  useEffect(() => {
    setDepartmentOptions(getDepartmentsOpts(context.settings.departments));
    _handleReset();
    // eslint-disable-next-line
  }, [context.settings, context.treeType]);
  return (
    <div>
      {context.isSearchBoxOpen && (
        <div
          className={[
            styles.searchContainer,
            fcCSS.verticalScrollContainer,
            fcCSS.frostedGlassWhiteLiteBg,
          ].join(" ")}
        >
          <div
            className={[
              fcCSS.posSticky,
              fcCSS.posTop,
              fcCSS.bgWhite,
              fcCSS.boxShadowDown,
              fcCSS.zIndex1,
            ].join(" ")}
          >
            <div className={styles.sectionGroup}>
              <h4>Filters</h4>
              <span
                className={[
                  fcCSS.icons,
                  fcCSS.d16,
                  fcCSS.mrgnright5,
                  "material-symbols-rounded",
                  fcCSS.cursorPtr,
                ].join(" ")}
                onClick={cancelFunction}
              >
                close
              </span>
            </div>
            <div className={styles.sectionGroup}>
              <input
                ref={(el) => (searchTextEle = el)}
                type="text"
                className={["input", styles.width70].join(" ")}
                name="search"
                placeholder="Search"
                onChange={(event) => onSearchInputChange(event)}
                autoFocus
                autoComplete="off"
              />
              <input
                ref={(el) => (searchLevelEle = el)}
                type="text"
                className={[styles.levelInput, styles.width30].join(" ")}
                name="Level"
                placeholder="Level"
                onChange={(event: any) => {
                  context.setNodeLevel(Number(event.target.value));
                  onSearchLevelChange();
                }}
              />
            </div>
            <div className={styles.sectionGroup}>
              <Select
                components={selectComponentAnimation}
                isMulti
                defaultValue={null}
                onChange={onDepartmentChange}
                value={
                  resetBtn ? (selectedDepartments = []) : selectedDepartments
                }
                options={departmentOptions}
                className="dropdown"
                styles={{
                  indicatorSeparator: () => ({ display: "none" }),
                  singleValue: (base) => ({
                    ...base,
                    paddingRight: "10px",
                    color: "hsl(0, 0%, 20%)",
                    cursor: "pointer",
                  }),
                }}
                placeholder={"Departments"}
              />

              <div className={fcCSS.mrgnleft5}>
                <button onClick={_handleReset} className="smallBtn">
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className={fcCSS.pad2010}>{renderList()}</div>
        </div>
      )}
    </div>
  );
};

export default Search;
