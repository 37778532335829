import { useContext } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { AppContext } from "../app-context";
import { useNavigate } from "react-router-dom";
import styles from "../styles/header.module.scss";
import flexcssable from "../styles/flexcssable.module.scss";
import { useLogin } from "../utilities/authService";
import Loader from "./Loader";

const Header = () => {
  /**
   * Extracting values from msal and context assignment section
   */
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const UserName: any = context.userToken?.account?.name || "Profile";
  const login = useLogin();

  /**
   * _handleSideMenuBar function definition section
   * This function helps us to show and hide the side menu
   */
  const _handleSideMenuBar = () => {
    context.setSideMenuBar(!context.sideMenuBar);
  };
  return (
    <>
      <header className={[flexcssable.frostedGlassWhiteLiteBg].join(" ")}>
        <div
          className={[
            flexcssable.section1280,
            flexcssable.flex,
            flexcssable.flexAlignItemsCenter,
            flexcssable.flexJustifyBetween,
          ].join(" ")}
        >
          <div
            className={[styles.hamburgerIcon].join(" ")}
            onClick={_handleSideMenuBar}
          >
            <span className="material-symbols-rounded">menu</span>
          </div>
          <div
            id={styles.header_logo}
            className={[flexcssable.mrgn10].join(" ")}
            onClick={() => navigate("/dashboard")}
          ></div>
          <AuthenticatedTemplate>
            <div
              className={[
                flexcssable.flex,
                flexcssable.flexAlignItemsCenter,
              ].join(" ")}
            >
              <span
                className={["material-symbols-rounded", styles.font28].join(
                  " "
                )}
              >
                account_circle
              </span>
              <div
                id={styles.profile_label}
                className={[flexcssable.pad10].join(" ")}
              >
                <span className={[flexcssable.desktopVisible].join(" ")}>
                  {UserName}
                </span>
                <span className={[flexcssable.mobiVisible].join(" ")}>
                  {UserName.split(" ")[0]}
                </span>
              </div>
              <div className={[flexcssable.pad5].join(" ")}></div>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <button
              onClick={login}
              className={[styles.btn, flexcssable.mrgn10].join(" ")}
            >
              Log In
            </button>
          </UnauthenticatedTemplate>
        </div>
      </header>
      <Loader />
    </>
  );
};

export default Header;
