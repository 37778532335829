/**
 * cancelFunction function definition section
 * This is a common function and this function helps us to cancel/close the popup's in our application
 * @param type
 * @param setState
 * @param context
 * @param marginBottom
 */
export function cancelFunction(
  type: string,
  setState: (arg0: boolean) => void,
  context: { setNodeActionPopupFlag: (arg0: boolean) => void },
  marginBottom?: string
) {
  const elementId = document.getElementById("popupAnimation");
  if (elementId) {
    elementId.classList.add("actionPopup");
    if (marginBottom) elementId.style.marginBottom = marginBottom;
    elementId.style.animationDuration = "0.5s";
    if (type !== "popup") {
      elementId.style.top = "50%";
      elementId.style.transition = "transform 0.5s ease-out";
    } else if (type === "popup") {
      elementId.style.transition = "none";
    }
    elementId.style.transform = "translateY(100%)";
    setTimeout(() => {
      setState(false);
      context.setNodeActionPopupFlag(false);
    }, 100);
  }
}

/**
 * getDepartmentsOpts function definition section
 * This function helps us to ge/list departments id and name from departments api
 * @param apiRes
 * @returns modified response
 */
export function getDepartmentsOpts(apiRes: any) {
  let dptArray: any = [];
  if (apiRes?.length > 0) {
    apiRes?.forEach((item: any) => {
      dptArray.push({ value: item._id, label: item.name });
    });
  }
  return dptArray;
}

/**
 * getParentPath function definition section
 * This function helps us to get selected parent node's name and its path
 * @param _id
 * @param nodeData
 * @returns parent path and parent node name
 */
export function getParentPath(_id: any, nodeData: any) {
  let parentPath: any = [];
  let patentName: string = "";
  nodeData?.forEach((item: any) => {
    if (item._id === _id) {
      parentPath.push(item.parent_path);
      patentName = item.node_name;
    }
  });
  return {
    parentPath,
    patentName,
  };
}

/**
 * parentPathOptions function definition section
 * This function helps us to set parent node options
 * @param _id
 * @param nodeData
 * @returns node options array
 */
export function parentPathOptions(_id: any, nodeData: any) {
  let pathArray: any = [];
  if (nodeData?.length > 0) {
    nodeData?.forEach((item: any) => {
      if (item._id !== _id) {
        pathArray.push({ value: item._id, label: item.node_name });
      }
    });
  }
  return pathArray;
}

/**
 * nodeOptions function definition section
 * This function helps us to form node type options array
 * @param settingsData
 * @returns node type options array
 */

export function nodeOptions(settings: any) {
  console.log(settings);
  let nodeOptionArray: any = [];
  settings?.types_of_nodes?.forEach((item: any) => {
    nodeOptionArray.push({
      value: item?.node_type,
      label: item?.node_type,
      symbol:
        item?.node_symbol === "oval"
          ? "circle"
          : item?.node_symbol === "triangle"
          ? "change_history"
          : item?.node_symbol,
    });
  });
  return nodeOptionArray;
}
