import { useContext } from "react";
import { AppContext } from "../app-context";
import { cancelFunction } from "../utilities/helper";
import styles from "../styles/delete.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import { useDeleteNodeService } from "../utilities/apiService";

function DeleteNode({ setDeleteNode }: any) {
  /**
   * context and function call section
   */
  const context = useContext(AppContext);
  const callDeleteNodesAPI = useDeleteNodeService();

  /**
   * _deleteNode function definition section
   * This function helps us to disable the node
   * @param event
   */
  const _deleteNode = async (event: any) => {
    event.preventDefault();
    try {
      const apiRes: any = await callDeleteNodesAPI({
        id: context.nodeData["_id"],
      });
      context.setAlertData(apiRes);
      context.setIsAlertPopUp(true);
      context.setIsPageReload(true);
    } catch (error: any) {
      console.log("Error");
    }
    context.setNodeActionPopupFlag(false);
    context.setExpandAllNodes(false);
    context.setSearchListingNodes([]);
    context.setNodeLevel(0);
    context.setIsSearchBoxOpen(false);
  };
  /**
   * cancelPopup function definition section
   * This function helps us to close the popup
   */
  const cancelPopup = () => {
    cancelFunction("delete", setDeleteNode, context, "-3500px");
  };
  return (
    <div id="popupAnimation" className={styles.deleteNodeContainer}>
      <div className={styles.actionDiv}>
        <h1 className={styles.deleteNode}>Delete Node</h1>
        <span
          className={[
            "material-symbols-rounded",
            "icons",
            fcCSS.cursorPtr,
          ].join(" ")}
          onClick={cancelPopup}
        >
          close
        </span>
      </div>
      <div className={[fcCSS.pad10].join(" ")}>
        <h2 className={styles.title}>
          Are you sure you want to delete the{" "}
          <b style={{ padding: "0px 3px", color: "black" }}>
            {context.nodeData.node_name}
          </b>
          ?
        </h2>
      </div>
      <div className={[styles.actionButtonsContainer].join(" ")}>
        <div>
          <button onClick={cancelPopup} className={styles.buttonDiscard}>
            Discard
          </button>
          <button onClick={_deleteNode} className={styles.buttonChange}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteNode;
