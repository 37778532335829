import { useNavigate } from "react-router-dom";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { AppContext } from "../app-context";
import { useContext } from "react";

const PageNotFound = () => {
  /**
   * context section
   */
  const context = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div className="rootWrapper">
      <Header />
      {context.sideMenuBar && <SideMenu />}
      <div className="pageNotFoundContainer">
        <div className="pageNotFoundImage"></div>
        <button
          type="button"
          className="btn"
          onClick={() => navigate("/dashboard")}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
