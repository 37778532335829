import styles from "../styles/activityLog.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import "../styles/graph.module.scss";
/**
 * ActivityLogExpandCollapse function definition section
 * This function helps us to expand and view all information in every single row in the table.
 * @param param0
 * @returns expanded view UI
 */
const ActivityLogExpandCollapse = ({ data }: any) => {
  return (
    <>
      {data.additional_info?.length > 0 && (
        <div className={[styles.activityExpandTable, fcCSS.flex].join(" ")}>
          <div>
            <div className={styles.cell}>
              <h5>Sr No</h5>
            </div>
            {data.additional_info?.length > 0 &&
              data.additional_info.map((card: any, index: any) => (
                <div key={index} className={styles.cell}>
                  {index + 1}
                </div>
              ))}
          </div>
          <div>
            <div className={styles.cell}>
              <h5>Name</h5>
            </div>
            {data.additional_info?.length > 0 &&
              data.additional_info.map((card: any, index: any) => (
                <div key={index} className={styles.cell}>
                  {card.node_name}
                </div>
              ))}
          </div>
          <div>
            <div className={styles.cell}>
              <h5>Tree type</h5>
            </div>
            {data.additional_info?.length > 0 &&
              data.additional_info.map((card: any, index: any) => (
                <div key={index} className={styles.cell}>
                  {card.tree_type}
                </div>
              ))}
          </div>
          <div>
            <div className={styles.cell}>
              <h5>Custodian</h5>
            </div>
            {data.additional_info?.length > 0 &&
              data.additional_info.map((card: any, index: any) => (
                <div key={index} className={styles.cell}>
                  {card.assigned_to}
                </div>
              ))}
          </div>
          <div>
            <div className={styles.cell}>
              <h5>Type</h5>
            </div>
            {data.additional_info?.length > 0 &&
              data.additional_info.map((card: any, index: any) => (
                <div key={index} className={styles.cell}>
                  {card.node_type}
                </div>
              ))}
          </div>
          <div>
            <div className={styles.cell}>
              <h5>Status</h5>
            </div>
            {data.additional_info?.length > 0 &&
              data.additional_info.map((card: any, index: any) => (
                <div key={index} className={styles.cell}>
                  {card.status}
                </div>
              ))}
          </div>
          {data.status === "node_edited" && (
            <div>
              <div className={styles.cell}>
                <h5>Update Status</h5>
              </div>
              {data.additional_info?.length > 0 &&
                ["Before Update", "After Update"].map(
                  (card: any, index: any) => (
                    <div className={[styles.cell].join(" ")} key={index}>
                      <p className={[styles.pill].join(" ")}>{card}</p>
                    </div>
                  )
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ActivityLogExpandCollapse;
